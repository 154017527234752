import { MergedDisplayWord } from "../../../../models/MergedDisplayWord";

export type MergedDisplayWordResponse = {
  displayText: string;
  offset: string;
  duration: string;
  participantId: string;
  participantName: string;
  absoluteOffset: string;
  relativeOffset: string;
};

const mergedDisplayWordResponseMapper = {
  toModel(response: MergedDisplayWordResponse): MergedDisplayWord {
    return {
      displayText: response.displayText,
      offset: response.offset,
      duration: response.duration,
      participantId: response.participantId,
      participantName: response.participantName,
      absoluteOffset: response.absoluteOffset,
      relativeOffset: response.relativeOffset,
    };
  },
};

export default mergedDisplayWordResponseMapper;
