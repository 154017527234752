import React from "react";
import HomePage from "./pages/home/HomePage";
import TeamMembersPage from "./pages/team-members/TeamMembersPage";
import MeetingPage from "./pages/touch-point/TouchPointPage";

import HomeLine from "@untitled-ui/icons-react/build/esm/HomeLine";
import Users02 from "@untitled-ui/icons-react/build/esm/Users02";
import Users01 from "@untitled-ui/icons-react/build/esm/Users01";
import Settings01 from "@untitled-ui/icons-react/build/esm/Settings01";
import ParticipantPage from "./pages/participant/ParticipantPage";
import ParticipantsPage from "./pages/participants/ParticipantsPage";
import TeamMemberPage from "./pages/team-member/TeamMemberPage";
import SettingsPage from "./pages/settings/SettingsPage";

export enum RoutePath {
  HOME = "/",
  TEAM_MEMBERS = "/team_members",
  TEAM_MEMBER = "/team_members/:id",
  SETTINGS = "/settings",
  LOGOUT = "/logout",
  INTERACTIONS = "/interactions",
  INTERACTION_DETAILS = "/interactions/:id",
  PARTICIPANTS = "/participants",
  PARTICIPANT = "/participants/:id",
}

export const defaultSidebarNavItems: SidebarNavItem[] = [
  {
    name: "Home",
    path: RoutePath.HOME,
    // add size to the icon
    icon: HomeLine,
  },
  {
    name: "Team Members",
    path: RoutePath.TEAM_MEMBERS,
    icon: Users02,
  },
  {
    name: "Participants",
    path: RoutePath.PARTICIPANTS,
    icon: Users01,
  },
  {
    name: "Settings",
    path: RoutePath.SETTINGS,
    icon: Settings01,
  },
];

// export const userSubSidebarNavItems: SidebarNavItem[] = [
//   {
//     name: "Logout",
//     path: RoutePath.LOGOUT,
//     icon: LogOut02,
//   },
// ];

export const routes: Route[] = [
  {
    name: "Home",
    path: RoutePath.HOME,
    component: HomePage,
    sidebarNavItems: defaultSidebarNavItems,
  },
  {
    name: "TeamMembers",
    path: RoutePath.TEAM_MEMBERS,
    component: TeamMembersPage,
    sidebarNavItems: defaultSidebarNavItems,
  },
  {
    name: "TeamMember",
    path: RoutePath.TEAM_MEMBER,
    component: TeamMemberPage,
    sidebarNavItems: defaultSidebarNavItems,
  },
  {
    name: "Interactions",
    path: RoutePath.INTERACTIONS,
    component: HomePage,
    sidebarNavItems: defaultSidebarNavItems,
  },
  {
    name: "InteractionDetails",
    path: RoutePath.INTERACTION_DETAILS,
    component: MeetingPage,
    sidebarNavItems: defaultSidebarNavItems,
  },
  {
    name: "Participants",
    path: RoutePath.PARTICIPANTS,
    component: ParticipantsPage,
    sidebarNavItems: defaultSidebarNavItems,
  },
  {
    name: "Participant",
    path: RoutePath.PARTICIPANT,
    component: ParticipantPage,
    sidebarNavItems: defaultSidebarNavItems,
  },
  {
    name: "Settings",
    path: RoutePath.SETTINGS,
    component: SettingsPage,
    sidebarNavItems: defaultSidebarNavItems,
  },
];

export interface SidebarNavItem {
  name: string;
  path: string;
  icon: React.ComponentType;
}

export interface Route {
  name: string;
  path: RoutePath;
  sidebarNavItems?: SidebarNavItem[];
  component: React.ComponentType;
}
