import { Integration } from "../../../models/Integration";

export interface AvailableIntegrationType {
    name: string;
    logoUrl: string;
    description: string;
    id: string;
    connected?: boolean;
    mergeName?: string;
    integrationDetails?: Integration;
    commingSoon?: boolean;
    isLoading?: boolean;
}


const availableCRMIntegrationTypes = [
    {
        name: 'HubSpot',
        mergeName: 'hubspot',
        logoUrl: 'https://logo.clearbit.com/hubspot.com',
        description: 'HubSpot is a customer relationship management system that helps businesses manage their sales, marketing, and customer service efforts.',
        id: '1'
    },
    {
        name: 'Salesforce',
        mergeName: 'salesforce',
        logoUrl: 'https://logo.clearbit.com/salesforce.com',
        description: 'Salesforce is a cloud-based customer relationship management (CRM) platform that helps businesses connect with and get more information about their customer base.',
        id: '2'
    },
    {
        name: 'Zendesk',
        mergeName: 'zendesk',
        logoUrl: 'https://logo.clearbit.com/zendesk.com',
        description: 'Zendesk is a customer service software company that provides a cloud-based customer support platform that includes ticketing, self-service options, and customer support features.',
        id: '3'
    },
    {
        name: 'Pipedrive',
        mergeName: 'pipedrive',
        logoUrl: 'https://logo.clearbit.com/pipedrive.com',
        description: 'Pipedrive is a web-based sales CRM and pipeline management solution that enables businesses to plan their sales activities and monitor deals.',
        id: '4',
        commingSoon: true
    },
    {
        name: 'Zoho',
        mergeName: 'zoho-crm',
        logoUrl: 'https://logo.clearbit.com/zoho.com',
        description: 'Zoho CRM is a cloud-based business management platform that caters to businesses of all sizes.',
        id: '5'
    },
    {
        name: 'Microsoft Dynamics',
        mergeName: 'microsoft-dynamics',
        logoUrl: 'https://logo.clearbit.com/microsoft.com',
        description: 'Microsoft Dynamics CRM is a customer relationship management software package developed by Microsoft.',
        id: '6',
        commingSoon: true
    }
];

const availablePhoneIntegrationTypes = [
{
        name: 'Twilio',
        logoUrl: 'https://logo.clearbit.com/twilio.com',
        description: 'Twilio is a cloud communications platform as a service company based in San Francisco, California.',
        id: '7',
        commingSoon: true
    },
    {
        name: 'Plivo',
        logoUrl: 'https://logo.clearbit.com/plivo.com',
        description: 'Plivo is a cloud-based communications platform that enables businesses to connect, engage, and interact with their customers.',
        id: '8',
        commingSoon: true
    },
    //8x8, ringcentral, aircall, microsoft teams, zoom, google voice
    {
        name: '8x8',
        logoUrl: 'https://logo.clearbit.com/8x8.com',
        description: '8x8 is a cloud-based VoIP phone system that offers voice, video, and chat services for businesses.',
        id: '9',
        commingSoon: true
    },
    {
        name: 'RingCentral',
        logoUrl: 'https://logo.clearbit.com/ringcentral.com',
        description: 'RingCentral is a cloud-based communications and collaboration platform that offers voice, video, and messaging services.',
        id: '10',
        commingSoon: true
    },
    {
        name: 'Aircall',
        logoUrl: 'https://logo.clearbit.com/aircall.io',
        description: 'Aircall is a cloud-based phone system that offers voice, video, and messaging services for businesses.',
        id: '11',
        commingSoon: true
    },
    {
        name: 'Microsoft Teams',
        logoUrl: 'https://logo.clearbit.com/microsoft.com',
        description: 'Microsoft Teams is a collaboration platform that offers voice, video, and messaging services for businesses.',
        id: '12',        commingSoon: false

    },
    {
        name: 'Zoom',
        logoUrl: 'https://logo.clearbit.com/zoom.us',
        description: 'Zoom is a cloud-based video conferencing platform that offers voice, video, and messaging services for businesses.',
        id: '13',        commingSoon: true

    },
    {
        name: 'Google Voice',
        logoUrl: 'https://logo.clearbit.com/voice.google.com',
        description: 'Google Voice is a cloud-based phone system that offers voice, video, and messaging services for businesses.',
        id: '14',
        commingSoon: true
    }
];

export {
    availableCRMIntegrationTypes,
    availablePhoneIntegrationTypes
};