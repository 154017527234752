import { ITranscriptionnApiClient } from "../clients/transcriptions/ITranscriptionApiClient";
import transcriptionResponseMapper from "../clients/transcriptions/models/responses/TranscriptionResponse";
import { Transcription } from "../models/Transcription";
import { CommVisionToken } from "../security/commVisionToken";

export class TranscriptionService {
  constructor(
    private readonly transcriptionApiClient: ITranscriptionnApiClient
  ) {}

  public async getTranscriptionByTouchPointId(
    authDetails: CommVisionToken | null,
    touchPointId: string
  ): Promise<Transcription | undefined> {
    const response =
      await this.transcriptionApiClient.getTranscriptionByTouchPointId(
        authDetails,
        touchPointId
      );

    if (!response) {
      return undefined;
    }

    return transcriptionResponseMapper.toModel(response);
  }
}
