import axios from "axios";
import { logger } from "../../infrastructure/logger";
import { IMeetingClient } from "./IMeetingClient";
import { Meeting } from "./models/Meeting";
import { DetailsOfMeetingsResponse } from "./models/responses/DetailsOfMeetingsResponse";
import { MeetingParticipantsResponse } from "./models/responses/MeetingParticipantsResponse";
import { ParticipantsResponse } from "./models/responses/ParticipantsResponse";
import { Participant } from "./models/Participant";
import { Company } from "./models/Company";
import { CompaniesResponse } from "./models/responses/CompaniesResponse";
import { CommVisionToken } from "../../security/commVisionToken";
import { MeetingSummary } from "./models/MeetingSummary";
import { SortBy } from "../../models/SortBy";
import { SortByRequest } from "../sharedModels/requests/SortByRequest";
import { Search } from "../../models/Search";
import { SearchRequest } from "../sharedModels/requests/SearchRequest";

const BASE_URL = process.env.REACT_APP_MEETINGS_API_URL + "/v1";

const meetingApiV1Client: IMeetingClient = {
  maxPageSize: 50,
  startingPageNumber: 1,

  async getMeetings(
    authDetails: CommVisionToken | null,
    page = undefined,
    pageSize = undefined
  ): Promise<DetailsOfMeetingsResponse> {
    if (!authDetails) {
      throw new Error("Authentication failed");
    }

    const { customerId, token } = authDetails;

    if (!page) page = this.startingPageNumber;
    if (!pageSize) pageSize = this.maxPageSize;

    logger.log("getMeetings", { customerId, page, pageSize });

    const result = await axios.get(`${BASE_URL}/details`, {
      params: { customerId, page, pageSize },
      headers: { Authorization: `Bearer ${token}` },
      withCredentials: true,
    });

    if (result.status === 200) {
      return result.data;
    } else {
      throw new Error(
        `Failed to get details of meetings: ${result.status} - ${result.statusText}`
      );
    }
  },

  async getParticipants(
    authDetails: CommVisionToken | null,
    ignoreInternalParticipants: boolean,
    page = undefined,
    pageSize = undefined,
    sortBy?: SortBy[]
  ): Promise<ParticipantsResponse> {
    if (!authDetails) {
      throw new Error("Authentication failed");
    }

    const { customerId, token } = authDetails;

    if (!page) page = this.startingPageNumber;
    if (!pageSize) pageSize = this.maxPageSize;

    logger.log("getParticipants", { customerId, page, pageSize, sortBy });

    let sort;

    if (sortBy) {
      sort = SortByRequest.ConvertFrom(sortBy);
    }

    const result = await axios.get(`${BASE_URL}/participants`, {
      params: {
        customerId,
        page,
        pageSize,
        sortBy: sort,
        ignoreInternalParticipants,
      },
      headers: { Authorization: `Bearer ${token}` },
      withCredentials: true,
    });

    if (result.status === 200) {
      return result.data;
    } else {
      throw new Error(
        `Failed to get participants: ${result.status} - ${result.statusText}`
      );
    }
  },

  async searchParticipants(
    authDetails: CommVisionToken | null,
    search: Search,
    ignoreInternalParticipants: boolean,
    page?: number,
    pageSize?: number,
    sortBy?: SortBy[]
  ): Promise<ParticipantsResponse> {
    if (!authDetails) {
      throw new Error("Authentication failed");
    }

    const { customerId, token } = authDetails;

    if (!page) page = this.startingPageNumber;
    if (!pageSize) pageSize = this.maxPageSize;

    logger.log("searchParticipants", {
      customerId,
      page,
      pageSize,
      sortBy,
      search,
    });

    let sort;

    if (sortBy) {
      sort = SortByRequest.ConvertFrom(sortBy);
    }

    const result = await axios.get(`${BASE_URL}/participants`, {
      params: {
        customerId,
        page,
        pageSize,
        sortBy: sort,
        search: SearchRequest.ConvertFrom(search),
        ignoreInternalParticipants,
      },
      headers: { Authorization: `Bearer ${token}` },
      withCredentials: true,
    });

    if (result.status === 200) {
      return result.data;
    } else {
      throw new Error(
        `Failed to search participants: ${result.status} - ${result.statusText}`
      );
    }
  },

  async getParticipant(
    authDetails: CommVisionToken | null,
    id: string
  ): Promise<Participant> {
    if (!authDetails) {
      throw new Error("Authentication failed");
    }

    const { customerId, token } = authDetails;

    logger.log("getParticipant", { id, customerId });

    const result = await axios.get(`${BASE_URL}/participants/${id}`, {
      params: { customerId },
      headers: { Authorization: `Bearer ${token}` },
      withCredentials: true,
    });

    if (result.status === 200) {
      return result.data;
    } else {
      throw new Error(
        `Failed to get participant by Id: ${result.status} - ${result.statusText}`
      );
    }
  },

  async getParticipantsForMeeting(
    authDetails: CommVisionToken | null,
    meetingId: string,
    organizerId: string,
    page = undefined,
    pageSize = undefined
  ): Promise<MeetingParticipantsResponse> {
    if (!authDetails) {
      throw new Error("Authentication failed");
    }

    const { customerId, token } = authDetails;

    if (!page) page = this.startingPageNumber;
    if (!pageSize) pageSize = this.maxPageSize;

    logger.log("getParticipantsForMeeting", {
      meetingId,
      organizerId,
      customerId,
      page,
      pageSize,
    });

    const result = await axios.get(
      `${BASE_URL}/details/${meetingId}/participants`,
      {
        params: { customerId, page, pageSize },
        headers: { Authorization: `Bearer ${token}` },
        withCredentials: true,
      }
    );

    if (result.status === 200) {
      return result.data;
    } else {
      throw new Error(
        `Failed to get participants for meeting: ${result.status} - ${result.statusText}`
      );
    }
  },

  async getMeetingSummary(
    authDetails: CommVisionToken | null,
    id: string
  ): Promise<MeetingSummary> {
    if (!authDetails) {
      throw new Error("Authentication failed");
    }

    const { customerId, token } = authDetails;
    logger.log("getMeetingSummary", { id, customerId });

    const result = await axios.get(`${BASE_URL}/transcriptions/v1/summary`, {
      params: { customerId, meetingId: id },
      headers: { Authorization: `Bearer ${token}` },
      withCredentials: true,
    });

    if (result.status === 200) {
      return result.data;
    } else {
      throw new Error(
        `Failed to get meeting summary: ${result.status} - ${result.statusText}`
      );
    }
  },

  async getMeeting(
    authDetails: CommVisionToken | null,
    id: string
  ): Promise<Meeting> {
    if (!authDetails) {
      throw new Error("Authentication failed");
    }

    const { customerId, token } = authDetails;
    logger.log("getMeeting", { id, customerId });

    const result = await axios.get(`${BASE_URL}/touchpoints/${id}`, {
      params: { customerId },
      headers: { Authorization: `Bearer ${token}` },
      withCredentials: true,
    });

    if (result.status === 200) {
      return result.data;
    } else {
      throw new Error(
        `Failed to get meeting details: ${result.status} - ${result.statusText}`
      );
    }
  },

  async getMeetingsByParticipant(
    authDetails: CommVisionToken | null,
    participantId: string,
    page = undefined,
    pageSize = undefined
  ): Promise<DetailsOfMeetingsResponse> {
    if (!authDetails) {
      throw new Error("Authentication failed");
    }

    const { customerId, token } = authDetails;

    if (!page) page = this.startingPageNumber;
    if (!pageSize) pageSize = this.maxPageSize;

    logger.log("getMeetingsByOrganizer", {
      participantId: participantId,
      customerId,
      page,
      pageSize,
    });

    const result = await axios.get(
      `${BASE_URL}/details/participant/${participantId}`,
      {
        params: { customerId, page, pageSize },
        headers: { Authorization: `Bearer ${token}` },
        withCredentials: true,
      }
    );

    if (result.status === 200) {
      return result.data;
    } else {
      throw new Error(
        `Failed to get meetings by participant: ${result.status} - ${result.statusText}`
      );
    }
  },

  async getRecordingUrlsForMeeting(
    authDetails: CommVisionToken | null,
    meetingId: string
  ): Promise<string[]> {
    logger.log("getRecordingUrlsForMeeting", { meetingId });

    //mediaRetrival/{customerId}/{meetingId}
    const result = await axios.get(
      `https://dev-media-storage-service.commvision.ai/api/mediaRetrival/${authDetails?.customerId}/${meetingId}`,
      {
        withCredentials: false,
      }
    );

    if (result.status === 200) {
      return result.data;
    } else {
      throw new Error(
        `Failed to get recording urls for meeting: ${result.status} - ${result.statusText}`
      );
    }
  },

  async getCompany(
    authDetails: CommVisionToken | null,
    id: string
  ): Promise<Company> {
    if (!authDetails) {
      throw new Error("Authentication failed");
    }

    const { customerId, token } = authDetails;

    logger.log("getCompany", { id, customerId });

    const result = await axios.get(`${BASE_URL}/companies/${id}`, {
      params: { customerId },
      headers: { Authorization: `Bearer ${token}` },
      withCredentials: true,
    });

    if (result.status === 200) {
      return result.data;
    } else {
      throw new Error(
        `Failed to get company by Id: ${result.status} - ${result.statusText}`
      );
    }
  },

  async getCompanies(
    authDetails: CommVisionToken | null,
    page = undefined,
    pageSize = undefined
  ): Promise<CompaniesResponse> {
    if (!authDetails) {
      throw new Error("Authentication failed");
    }

    const { customerId, token } = authDetails;

    if (!page) page = this.startingPageNumber;
    if (!pageSize) pageSize = this.maxPageSize;

    logger.log("getCompanies", { customerId, page, pageSize });

    const result = await axios.get(`${BASE_URL}/companies`, {
      params: { customerId, page, pageSize },
      headers: { Authorization: `Bearer ${token}` },
      withCredentials: true,
    });

    if (result.status === 200) {
      return result.data;
    } else {
      throw new Error(
        `Failed to get companies: ${result.status} - ${result.statusText}`
      );
    }
  },

  async getMeetingsByCompany(
    authDetails: CommVisionToken | null,
    companyId: string,
    page = undefined,
    pageSize = undefined
  ): Promise<DetailsOfMeetingsResponse> {
    if (!authDetails) {
      throw new Error("Authentication failed");
    }

    const { customerId, token } = authDetails;

    if (!page) page = this.startingPageNumber;
    if (!pageSize) pageSize = this.maxPageSize;

    logger.log("getMeetingsByCompany", {
      organizerId: companyId,
      customerId,
      page,
      pageSize,
    });

    const result = await axios.get(`${BASE_URL}/details/company/${companyId}`, {
      params: { customerId, page, pageSize },
      headers: { Authorization: `Bearer ${token}` },
      withCredentials: true,
    });

    if (result.status === 200) {
      return result.data;
    } else {
      throw new Error(
        `Failed to get meetings by company: ${result.status} - ${result.statusText}`
      );
    }
  },
};

export default meetingApiV1Client;
