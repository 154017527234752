import { ConfigurationType } from "../../../models/ConfigurationType";
import { getIntegrationDetails, Integration, IntegrationType } from "../../../models/Integration";
import configurationDataResponseMapper, {
  ConfigurationDataResponse,
} from "./ConfigurationDataResponse";

export type IntegrationResponse = {
  id: string;
  configurationType: ConfigurationType;
  configurationData: ConfigurationDataResponse;
  name: string;
  logoUrl?: string;
  integrationDetails?: IntegrationType;
};

const integrationResponseMapper = {
  toModel(response: IntegrationResponse): Integration {

    const integrationType = getIntegrationDetails(response.name);
    if(integrationType) {
      response.integrationDetails = integrationType;
    }

    return {
      id: response.id,
      configurationType: response.configurationType,
      configurationData: configurationDataResponseMapper.toModel(
        response.configurationData
      ),
      name: response.name,
      logoUrl: response.logoUrl,
      integrationDetails: response.integrationDetails,
    };
  },
};

export default integrationResponseMapper;
