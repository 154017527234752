import { SortDirection } from "../models/SortBy";

interface SortableTableHeadingProps {
  headingText: string;
  headingToSort: string;
  sortColumnDirection: SortDirection;
  activeColumns: string[];
  sortingColumns: string[];
  sortByColumnOnClick: (column: string, sortDirection: SortDirection) => void;
}

const SortableTableHeading = ({
  headingText,
  headingToSort,
  sortColumnDirection,
  activeColumns,
  sortingColumns,
  sortByColumnOnClick,
}: SortableTableHeadingProps) => {
  return (
    <div className="flex items-center">
      <svg
        className={`w-4 h-4 cursor-pointer ${
          activeColumns?.includes(headingToSort)
            ? "text-black"
            : "text-[#BCBDBE] group-hover:text-black rotate-180"
        } ${sortingColumns?.includes(headingToSort) ? "rotate-180" : "rotate-0"}
                `}
        onClick={() => sortByColumnOnClick(headingToSort, sortColumnDirection)}
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M19 14l-7 7m0 0l-7-7m7 7V3"
        />
      </svg>
      <span
        className="cursor-pointer pl-1"
        onClick={() => sortByColumnOnClick(headingToSort, sortColumnDirection)}
      >
        {headingText}
      </span>
    </div>
  );
};

export default SortableTableHeading;
