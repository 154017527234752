import { EventType } from "../../../../models/EventType";
import { EventSource } from "../../../../models/EventSource";
import { MetaData } from "../../../../models/MetaData";
import processStatusResponseMapper, {
  ProcessStatusResponse,
} from "../../../meetings/models/responses/ProcessStatusResponse";

export type MetaDataResponse = {
  eventSource: EventSource;
  eventType: EventType;
  externalId: string;
  correlationId: string;
  processStatus?: ProcessStatusResponse;
};

const metaDataResponseMapper = {
  toModel(response: MetaDataResponse): MetaData {
    return {
      eventSource: response.eventSource,
      eventType: response.eventType,
      externalId: response.externalId,
      correlationId: response.correlationId,
      processStatus: response.processStatus
        ? processStatusResponseMapper.toModel(response.processStatus)
        : undefined,
    };
  },
};

export default metaDataResponseMapper;
