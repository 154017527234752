import { ClipLoader } from "react-spinners";

export const LoadingCard = () => {
  return (
    <div
    className={
      "rounded-lg border-gray-200 border shadow-lg bg-white relative"
    }
  >
    <div className="flex items-center justify-between p-4">
      <div className="flex items-center">
        <div className="ml-2">
        </div>
      </div> 
    </div>
    <div className="p-4 min-h-24">
    <div className="absolute inset-0 bg-white bg-opacity-75 flex items-center justify-center">
        <ClipLoader color={"#0721B3"} loading={true} size={50} />
      </div>
    </div> 
  </div> 
  );
};
