import { Transcription } from "../../../../models/Transcription";
import mergedDisplayWordResponseMapper, {
  MergedDisplayWordResponse,
} from "./MergedDisplayWordResponse";

export type TranscriptionResponse = {
  transcription?: {
    conversationSummary?: string | null;
    mergedDisplayWords?: MergedDisplayWordResponse[];
  };
  combinedMediaUrl?: string;
};

const transcriptionResponseMapper = {
  toModel(response: TranscriptionResponse): Transcription {
    return {
      transcription: {
        conversationSummary: response.transcription?.conversationSummary ?? "",
        mergedDisplayWords: response.transcription?.mergedDisplayWords?.map(
          (mergedDisplayWord) =>
            mergedDisplayWordResponseMapper.toModel(mergedDisplayWord)
        ) ?? [],
      },
      combinedMediaUrl: response.combinedMediaUrl ?? "",
    };
  },
};

export default transcriptionResponseMapper;
