import Avatar from "react-avatar";
import { IoBriefcaseOutline, IoMail } from "react-icons/io5";
import { Participant } from "../clients/meetings/models/Participant";
import { FaPhoneAlt } from "react-icons/fa";

interface ParticipantDetailProps {
  participant: Participant;
}

export default function ParticipantDetailBar({
  participant,
}: ParticipantDetailProps) {
  return (
    <div className="flex flex-cols-6 gap-8 justify-start content-start align-middle text-xl mt-8 mb-8 w-[80vw]">
      {participant.name && (
        <div className="col">
          <div className="flex items-center gap-2">
            <Avatar
              name={participant?.name}
              size="24"
              round={true}
              textMarginRatio={0.05}
            />
            <div className="font-medium text-grey-600">
              <div>{participant?.name}</div>
            </div>
          </div>
        </div>
      )}
      {participant.email && (
        <div className="col">
          <div className="flex items-center gap-2">
            <div className="icon">
              <IoMail />
            </div>
            <div className="font-medium text-grey-600">
              <div>{participant.email}</div>
            </div>
          </div>
        </div>
      )}
      {participant.company && (
        <div className="col">
          <div className="flex items-center gap-2">
            <div className="icon">
              <IoBriefcaseOutline />
            </div>
            <div className="font-medium text-grey-600">
              <div>{participant.company.name}</div>
            </div>
          </div>
        </div>
      )}
      {participant.phoneNumbers?.map((phoneNumber) => (
        <div className="col" key={phoneNumber}>
          <div className="flex items-center gap-2">
            <div className="icon">
              <FaPhoneAlt />
            </div>
            <div className="font-medium text-grey-600">
              <div>{phoneNumber}</div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
