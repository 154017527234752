import { CustomerConfiguration } from "../../../models/CustomerConfiguration";
import integrationResponseMapper, {
  IntegrationResponse,
} from "./IntegrationResponse";

export type CustomerConfigurationResponse = {
  id: string;
  customerId: string;
  integrations: IntegrationResponse[];
  created: Date;
  updated?: Date | undefined;
  crmIntegrations?: IntegrationResponse[];
  phoneIntegrations?: IntegrationResponse[];
};

const customerConfigurationResponseMapper = {
  toModel(response: CustomerConfigurationResponse): CustomerConfiguration {
    return {
      id: response.id,
      customerId: response.customerId,
      integrations: response.integrations?.map((integration) =>
        integrationResponseMapper.toModel(integration)
      ),
      crmIntegrations: response.crmIntegrations?.map((integration) =>
        integrationResponseMapper.toModel(integration)
      ),
      phoneIntegrations: response.phoneIntegrations?.map((integration) =>
        integrationResponseMapper.toModel(integration)
      ),
      created: response.created,
      updated: response.updated,
    };
  },
};

export default customerConfigurationResponseMapper;
