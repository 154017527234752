import React from "react";
import { useAuth } from "react-oidc-context";
import LogOut02 from "@untitled-ui/icons-react/build/esm/LogOut02";
import LogIn02 from "@untitled-ui/icons-react/build/esm/LogIn02";

const AuthButton: React.FC = () => {
  const auth = useAuth();

  const handleLogout = () => {
    auth.signoutRedirect();
  };

  const handleLogin = () => {
    auth.signinRedirect();
  };

  return (
    <div>
      {auth.isAuthenticated ? (
        <button onClick={handleLogout}>
          <LogOut02 />
        </button>
      ) : (
        <button onClick={handleLogin}>
          <LogIn02 />
        </button>
      )}
    </div>
  );
};

export default AuthButton;
