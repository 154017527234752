import { SortBy } from "../../../models/SortBy";

export namespace SortByRequest {
  export function ConvertFrom(sortBy: SortBy[]): string {
    return (
      "[" +
      sortBy.map(
        (x) =>
          `{"propertyName":"${x.propertyName}", "direction":"${x.direction}"}`
      ) +
      "]"
    );
  }
}
