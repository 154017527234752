import { TeamMemberWithTouchPoint } from "../models/TeamMemberWithLatestTouchPoint";
import { Tooltip } from "react-tooltip";
import Avatar from "react-avatar";
import moment from "moment";

interface TeamMembersListProps {
  teamMembers: TeamMemberWithTouchPoint[];
}

const TeamMembersList = ({ teamMembers }: TeamMembersListProps) => {
  return (
    <div className="relative flex flex-col w-full h-full text-gray-700 bg-white shadow-md rounded-lg bg-clip-border">
      <table className="table-auto w-full text-left min-w-full divide-y divide-gray-200  border border-gray-200 rounded-lg p-3">
        <thead>
          <tr>
            <th className="p-2 px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 ">
              Team Member
            </th>
            <th className="p-2 px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 ">
              Latest Touch Point
            </th>
            <th className="p-2 px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 ">
              Date
            </th>
            <th className="p-2 px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 ">
              Company
            </th>
            <th className="p-2 px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 ">
              Participants
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 ">
          {teamMembers.map((teamMember) => {
            return (
              <tr key={teamMember.teamMember.id}>
                <td className="px-6 py-4 whitespace-nowrap text-md font-bold text-gray-800 ">
                  <a href={`/team_members/${teamMember.teamMember.id}`}>
                    <div className="flex items-center gap-2">
                      <Avatar
                        name={teamMember.teamMember.name}
                        size="30"
                        round={true}
                        textMarginRatio={0.05}
                      />
                      <div className="font-medium text-grey-600">
                        <div>{teamMember.teamMember.name}</div>
                      </div>
                    </div>
                  </a>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-md font-bold capitalize border-l-gray-200 border-r-0 border ">
                  <a href={`/interactions/${teamMember.touchPoint.id}`}>
                    {teamMember.touchPoint.subject}
                  </a>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-md font-medium text-gray-800 ">
                  {moment(teamMember.touchPoint.startDateTime).format(
                    "MMM Do [at] h:mm a"
                  )}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-md font-medium text-gray-800 ">
                  {teamMember.touchPoint.company?.name}
                </td>

                <td className="px-6 py-4 whitespace-nowrap text-md font-medium text-gray-800 ">
                  <div className="flex -space-x-2 rtl:space-x-reverse">
                    {teamMember.touchPoint.participants
                      .slice(0, 5)
                      .map((participant, index) => (
                        <div
                          className="rounded-full "
                          key={index + participant.id}
                        >
                          <Tooltip id={index + participant.id} />

                          <Avatar
                            name={participant.name}
                            size="30"
                            round={true}
                            textMarginRatio={0.05}
                            data-tooltip-id={index + participant.id}
                            data-tooltip-content={participant.name}
                            data-tooltip-place="left"
                          />
                        </div>
                      ))}

                    {teamMember.touchPoint.participants.length > 5 && (
                      <div className="cursor-pointer flex items-center justify-center w-7 text-xs font-medium text-white bg-gray-700 rounded-full hover:bg-gray-600  !ml-1">
                        <span>
                          +{teamMember.touchPoint.participants.length - 5}
                        </span>
                      </div>
                    )}
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default TeamMembersList;
