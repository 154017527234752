import processStatusMapper from "../../../../mappers/ProcessStatusMapper";
import {
  ProcessorStatusType,
  ProcessStatus,
  ProcessStatusStep,
} from "../../../../models/ProcessesStatus";
import { Meeting } from "../Meeting";

export interface ProcessStatusResponse {
  id: string;
  customerId: string;
  createdOn: Date;
  rawEventId: string;
  statusText: string;
  statusType: ProcessorStatusType;
  step: ProcessStatusStep;
  meetingId?: string;
  meetingParticipantId?: string;
  participantId?: string;
  participantIds?: string[];
}

export function getLatestProcessStatus(
  meeting: Meeting
): ProcessStatusResponse | undefined {
  if (meeting.processStatuses?.length === 1) {
    return meeting.processStatuses[0];
  }

  const completedStatus = meeting.processStatuses?.find(
    (x) =>
      x.statusType === ProcessorStatusType.COMPLETED ||
      x.statusType === ProcessorStatusType.COMPLETE
  );

  if (completedStatus) return completedStatus;

  return meeting.processStatuses
    ?.slice()
    .sort(
      (a, b) =>
        new Date(b.createdOn).getTime() - new Date(a.createdOn).getTime()
    )[0];
}

const processStatusResponseMapper = {
  toModel(response: ProcessStatusResponse): ProcessStatus {
    return {
      id: response.id,
      customerId: response.customerId,
      createdOn: response.createdOn,
      statusText: response.statusText,
      statusType: response.statusType,
      step: response.step,
      status: processStatusMapper.toProcessStatus(response.statusType),
    };
  },
};

export default processStatusResponseMapper;
