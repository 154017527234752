import { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import { Participant } from "../../clients/meetings/models/Participant";
import { meetingApiClientFactory } from "../../clients/meetings/MeetingApiClientFactory";
import { PaginationProps } from "../../components/Pagination";
import { useAuthDetails } from "../../hooks/useAuthDetails";
import HomeLine from "@untitled-ui/icons-react/build/esm/HomeLine";
import BreadCrumb from "../../components/BreadCrumb";
import ParticipantDetailBar from "../../components/ParticipantDetailBar";
import TouchPointsList from "../../components/TouchPointsList";
import { TouchPoint } from "../../models/TouchPoint";
import { SortDirection } from "../../models/SortBy";
import { ITouchPointService } from "../../services/ITouchPointService";
import { TouchPointApiClient } from "../../clients/touchpoints/TouchPointApiClient";
import { TouchPointService } from "../../services/TouchPointService";

const touchPointService: ITouchPointService = new TouchPointService(
  new TouchPointApiClient()
);

export default function TeamMemberPage() {
  const authDetails = useAuthDetails();
  const [loading, setLoading] = useState(false);
  const [participant, setParticipant] = useState<Participant | null>(null);
  const [error, setError] = useState<string | null>(null);
  const params = useParams();
  const id = params.id!;

  const defaultItemsPerPage = 25;
  const defaultSortingColumn = "Date";
  const [touchPoints, setTouchPoints] = useState<TouchPoint[]>([]);
  const [sortingColumn, setSortingColumn] = useState([defaultSortingColumn]);
  const [activeColumn, setActiveColumn] = useState([defaultSortingColumn]);
  const [sortingDirection, setSortingDirection] = useState<SortDirection>(
    SortDirection.Ascending
  );
  const [itemsPerPage, setItemsPerPage] = useState(defaultItemsPerPage);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const paginationSettings: PaginationProps = {
    currentPage,
    itemsPerPage,
    totalItems,
    onPageChange: handlePageChange,
  };

  const breadCrumbs = [
    { name: "Home", link: "/", icon: HomeLine },
    { name: "Team Members", link: "/team_members" },
    {
      name: participant?.name || participant?.email || "Team Member",
      link: `/team_members/${id}`,
      active: true,
    },
  ];

  const loadTouchPoints = useCallback(
    async (
      participantId: string,
      sortByColumn?: string,
      sortByDirection?: SortDirection
    ) => {
      if (!authDetails) return;

      const sortBy =
        sortByColumn && sortByDirection
          ? [{ propertyName: sortByColumn, direction: sortByDirection }]
          : undefined;

      const data = await touchPointService.getTouchPointsByParticipantId(
        authDetails,
        participantId,
        currentPage,
        itemsPerPage,
        sortBy
      );

      if (!data?.items) {
        return;
      }

      if (data.totalCount > totalItems) {
        setItemsPerPage(itemsPerPage);
      }

      setTouchPoints(data.items || []);
      setTotalItems(data.totalCount || 0);
    },
    [authDetails, currentPage, totalItems, itemsPerPage]
  );

  const loadParticipant = useCallback(async () => {
    if (!authDetails) return;

    try {
      const data = await meetingApiClientFactory.client
        .v1()
        .getParticipant(authDetails, id);
      setParticipant(data);
    } catch (e) {
      if (e instanceof Error) {
        setError(e.message);
      }
    }
  }, [authDetails, id]);

  useEffect(() => {
    setLoading(true);
    Promise.all([loadParticipant(), loadTouchPoints(id)])
      .catch((e) => {
        if (e instanceof Error) {
          setError(e.message);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [loadParticipant, loadTouchPoints, id]);

  const sortByColumnOnClick = (column: string) => {
    if (sortingColumn?.includes(column)) {
      // if (resultsAreFiltered) {
      //   loadFilteredTouchPoints(searchText!, column, SortDirection.Descending);
      // } else {
      loadTouchPoints(id, column, SortDirection.Descending);
      // }

      setSortingColumn([]);
      setSortingDirection(SortDirection.Descending);
    } else {
      // if (resultsAreFiltered) {
      //   loadFilteredTouchPoints(searchText!, column, SortDirection.Ascending);
      // } else {
      loadTouchPoints(id, column, SortDirection.Ascending);
      // }

      setSortingColumn([`${column}`]);
      setSortingDirection(SortDirection.Ascending);
    }

    setActiveColumn([`${column}`]);
  };

  return (
    <div>
      {participant && (
        <>
          <BreadCrumb items={breadCrumbs} />
          <ParticipantDetailBar participant={participant} />

          <div className="mt-5">
            {error && (
              <div className="row">
                <div className="card large error">
                  <section>
                    <p>
                      <span className="icon-alert inverse "></span>
                      {error}
                    </p>
                  </section>
                </div>
              </div>
            )}

            <TouchPointsList
              touchPoints={touchPoints}
              pagination={paginationSettings}
              sortDirection={sortingDirection}
              activeColumns={activeColumn}
              sortingColumns={sortingColumn}
              sortByColumnOnClick={sortByColumnOnClick}
              onSearchChange={(searchPhrase: string) => void {}}
              disableSearch={true}
            />

            {loading && (
              <div className="center-page">
                <span className="spinner primary"></span>
                <p>Loading...</p>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
}
