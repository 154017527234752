interface SummaryProps {
  summaryText: string;
}

const SummaryContent = ({ summaryText }: SummaryProps) => {
  return (
    <div className="">
      {summaryText && <div className="text-lg">{summaryText}</div>}
    </div>
  );
};

export default SummaryContent;
