import React from 'react';
import { withAuthenticationRequired } from 'react-oidc-context';

interface ProtectedRouteProps {
  component: React.ComponentType;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ component: Component }) => {
  return <Component />;
};

export default withAuthenticationRequired(ProtectedRoute, {
  OnRedirecting: () => <div>Redirecting to the login page...</div>,
  onBeforeSignin: () => {
    console.log('User is not signed in');
    // Store location to redirect to after login
    localStorage.setItem('redirectUri', window.location.pathname);
  }
  
});
