import { ICustomersApiClient } from "../clients/customers/ICustomersApiClient";
import customerConfigurationResponseMapper from "../clients/sharedModels/responses/CustomerConfigurationResponse";
import { logger } from "../infrastructure/logger";
import { CustomerConfiguration } from "../models/CustomerConfiguration";
import { CommVisionToken } from "../security/commVisionToken";
import { ICustomerService } from "./ICustomerService";

export class CustomerService implements ICustomerService {
  constructor(private readonly customerApiClient: ICustomersApiClient) {}

  public async getCustomerConfiguration(
    authDetails: CommVisionToken
  ): Promise<CustomerConfiguration | undefined> {
    if (!authDetails) {
      throw new Error("Missing auth details");
    }

    try {
      const response = await this.customerApiClient.getCustomerConfiguration(
        authDetails
      );

      if (response) {
        return customerConfigurationResponseMapper.toModel(response);
      }

      return undefined;
    } catch (error) {
      logger.error((error as Error).message);
      return undefined;
    }
  }
}
