import { Status } from "../models/ProcessesStatus";
import { Tooltip } from "react-tooltip";
import StatusContainer from "./StatusContainer";
import Avatar from "react-avatar";
import { TouchPoint } from "../models/TouchPoint";
import Pagination, { PaginationProps } from "./Pagination";
import SearchLg from "@untitled-ui/icons-react/build/esm/SearchLg";
import { IoTimeOutline } from "react-icons/io5";
import moment from "moment";
import SortableTableHeading from "./SortableTableHeading";
import { SortDirection } from "../models/SortBy";
import { DebouncedInput } from "./DebouncedInput";
import { useState } from "react";

interface TouchPointsListProps {
  touchPoints: TouchPoint[];
  pagination: PaginationProps;
  sortDirection: SortDirection;
  activeColumns: string[];
  sortingColumns: string[];
  sortByColumnOnClick: (column: string, sortDirection: SortDirection) => void;
  onSearchChange: (searchPhrase: string) => void;
  disableSearch?: boolean;
}

const TouchPointsList = ({
  touchPoints,
  pagination,
  sortDirection,
  activeColumns,
  sortingColumns,
  sortByColumnOnClick,
  onSearchChange,
  disableSearch = false,
}: TouchPointsListProps) => {
  const [showStatus, setShowStatus] = useState(false);

  const onShowStatusChange = (show: boolean) => {
    setShowStatus(show);
  };

  return (
    <>
      <div className="flex mb-6">
        <div className="flex items-center justify-start w-1/2">
          {/* <button className="flex items-center justify-center p-6 h-10 text-md border-slate-300 text-gray-700 border font-bold rounded-lg">
            All Time
            <span>
              <IoClose size={28} />
            </span>
          </button> */}
        </div>
        <div className="flex items-center justify-end w-1/2">
          <label className="inline-flex items-center mb-0 mr-5 cursor-pointer">
            <input
              type="checkbox"
              value=""
              className="sr-only peer"
              checked={showStatus}
              onChange={(e) => onShowStatusChange(e.target.checked)}
            />
            <div className="relative w-10 h-5 rounded-full peer bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all border-gray-600 peer-checked:gray-700" />
            <span className="ms-3 text-sm font-medium">Show Status</span>
          </label>

          {!disableSearch && (
            <div className="flex flex-row items-center border border-gray-300 rounded-md">
              <div className="p-2">
                <SearchLg />
              </div>
              <DebouncedInput
                value={""}
                onChange={onSearchChange}
                placeholder="Type to search..."
                delay={500} // Debounce delay in milliseconds
                className="w-full"
              />
            </div>
          )}
        </div>
      </div>
      <div className="">
        {/* table has light grey border around each row with first column big padding */}
        <table className="table-auto w-full text-left min-w-full divide-y divide-gray-200  border border-gray-200 rounded-lg p-3">
          <thead>
            <tr>
              <th className="p-2 px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 ">
                <SortableTableHeading
                  headingText="Subject"
                  headingToSort="Subject"
                  sortColumnDirection={sortDirection}
                  activeColumns={activeColumns}
                  sortingColumns={sortingColumns}
                  sortByColumnOnClick={sortByColumnOnClick}
                />
              </th>
              <th className="p-2 px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 ">
                <SortableTableHeading
                  headingText="Date"
                  headingToSort="StartDateTime"
                  sortColumnDirection={sortDirection}
                  activeColumns={activeColumns}
                  sortingColumns={sortingColumns}
                  sortByColumnOnClick={sortByColumnOnClick}
                />
              </th>
              <th className="p-2 px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 ">
                <SortableTableHeading
                  headingText="Total Duration"
                  headingToSort="TotalDurationInSeconds"
                  sortColumnDirection={sortDirection}
                  activeColumns={activeColumns}
                  sortingColumns={sortingColumns}
                  sortByColumnOnClick={sortByColumnOnClick}
                />
              </th>
              <th className="p-2 px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 ">
                Company
              </th>
              <th className="p-2 px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 ">
                Participants
              </th>

              {showStatus && (
                <th className="w-[120px] px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 ">
                  Process Status
                </th>
              )}
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 ">
            {touchPoints.map((touchPoint) => {
              return (
                <tr key={touchPoint.id}>
                  <td className="px-6 py-4 whitespace-nowrap text-md font-bold text-gray-800 ">
                    <a href={`/interactions/${touchPoint.id}`}>
                      {touchPoint.subject}
                    </a>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-md font-medium text-gray-800 ">
                    {moment(touchPoint.startDateTime).format(
                      "MMM Do [at] h:mm a"
                    )}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-md font-medium text-gray-800 ">
                    {moment
                      .duration(
                        Number(touchPoint.totalDurationInSeconds),
                        "seconds"
                      )
                      .humanize()}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-md font-medium text-gray-800 ">
                    {touchPoint.company?.name}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-md font-medium text-gray-800 ">
                    {!touchPoint.endDateTime && showStatus && (
                      <div className="col">
                        <div className="flex items-center gap-2">
                          <div className="icon">
                            <IoTimeOutline color="green" />
                          </div>
                          <div className="font-medium text-green-600">
                            <div>In progress</div>
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="flex -space-x-2 rtl:space-x-reverse">
                      {touchPoint.participants
                        .slice(0, 5)
                        .map((participant, index) => (
                          <div
                            key={index + participant.id}
                            className="rounded-full "
                          >
                            <Tooltip id={index + participant.id} />

                            <Avatar
                              name={participant.name}
                              size="30"
                              round={true}
                              textMarginRatio={0.05}
                              data-tooltip-id={index + participant.id}
                              data-tooltip-content={participant.name}
                              data-tooltip-place="left"
                            />
                          </div>
                        ))}

                      {touchPoint.participants.length > 5 && (
                        <div className="cursor-pointer flex items-center justify-center w-7 text-xs font-medium text-white bg-gray-700 rounded-full hover:bg-gray-600  !ml-1">
                          <span>+{touchPoint.participants.length - 5}</span>
                        </div>
                      )}
                    </div>
                  </td>
                  {showStatus && (
                    <td className="flex items-center justify-center px-6 py-4 whitespace-nowrap text-md font-medium">
                      <StatusContainer
                        status={
                          touchPoint.metaData.processStatus?.status ??
                          Status.Unknown
                        }
                        correlationId={touchPoint.metaData.correlationId}
                      />
                    </td>
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>

        {pagination.totalItems > pagination.itemsPerPage && (
          <Pagination
            currentPage={pagination.currentPage}
            itemsPerPage={pagination.itemsPerPage}
            totalItems={pagination.totalItems}
            onPageChange={pagination.onPageChange}
          />
        )}
      </div>
    </>
  );
};

export default TouchPointsList;
