import { Search } from "../../../models/Search";

export namespace SearchRequest {
  export function ConvertFrom(search: Search): string {
    if (!search.phrase) {
      return `{"phrase": null, "searchOn": ${JSON.stringify(search.searchOn)}}`;
    }

    return `{"phrase": "${search.phrase}", "searchOn": ${JSON.stringify(
      search.searchOn
    )}}`;
  }
}
