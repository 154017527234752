import React from "react";
import { PAGINATION_CONSTANTS } from "../configs/constants";

interface ItemsPerPageSelectorProps {
  itemsPerPage: number;
  onItemsPerPageChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  maxItemsPerPage: number;
}

const ItemsPerPageSelector: React.FC<ItemsPerPageSelectorProps> = ({
  itemsPerPage,
  onItemsPerPageChange,
  maxItemsPerPage,
}) => {
  return (
    <div>
      <label htmlFor="itemsPerPage">Items per page: </label>
      <select
        id="itemsPerPage"
        value={itemsPerPage}
        onChange={onItemsPerPageChange}
      >
        <option value={PAGINATION_CONSTANTS.DEFAULT_ITEMS_PER_PAGE}>
          {PAGINATION_CONSTANTS.DEFAULT_ITEMS_PER_PAGE}
        </option>
        <option value="30">30</option>
        <option value={maxItemsPerPage}>{maxItemsPerPage}</option>
      </select>
    </div>
  );
};

export default ItemsPerPageSelector;
