import { Outlet } from "react-router-dom";
import Sidebar from "./Sidebar";

const Layout = () => (
  <div className="flex flex-1">
    <Sidebar />
    <div className="flex flex-col flex-1 px-4 mb-28 pb-28 space-y-4 ml-7 mt-6 pl-28">
      <Outlet />
    </div>
  </div>
);

export default Layout;
