import { useAuthDetails } from "../../hooks/useAuthDetails";
import { useState } from "react";
import { ICustomerService } from "../../services/ICustomerService";
import { CustomerService } from "../../services/CustomerService";
import { ICustomersApiClient } from "../../clients/customers/ICustomersApiClient";
import { CustomersApiClient } from "../../clients/customers/CustomersApiClient";
import { CustomerConfiguration } from "../../models/CustomerConfiguration";
import { Tabs, Tab, TabList, TabPanel } from "react-tabs";
import BadgeCircle from "../../components/BadgeCircle";
import { TeamSettingsTab } from "./Tabs/TeamSettingsTab";
import { CRMintegrationsTab } from "./Tabs/CRMIntegrationsTab";
import { PhoneIntegrationsTab } from "./Tabs/PhoneIntegrationsTab";

const customerApiClient: ICustomersApiClient = new CustomersApiClient();
const customerService: ICustomerService = new CustomerService(
  customerApiClient
);

const SettingsPage = () => {
  const authDetails = useAuthDetails();
  const [tabIndex, setTabIndex] = useState(0);

  const [crmIntegrations, setCrmIntegrations] = useState<any[]>([]);
  const [touchPointIntegrations, setTouchPointIntegrations] = useState<any[]>([]);

  // Initialize customer configuration with lazy loading
  const [customerConfiguration, setCustomerConfiguration] = useState<
    CustomerConfiguration | undefined
  >(() => {
    // This runs only once when component mounts
    if (authDetails) {
      customerService.getCustomerConfiguration(authDetails).then((config) => {
        if (config) {
          setCustomerConfiguration(config);

          setCrmIntegrations(config.crmIntegrations ?? [])
          console.log(config.crmIntegrations, 'crmIntegrations')
          
          setTouchPointIntegrations(config.phoneIntegrations ?? []);
        }
      });
    }
    return undefined;
  });

  return (
    <div>
      <h1 className="text-4xl capitalize font-bold mb-8">Settings</h1>
      <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
        <TabList>
          <Tab>Team</Tab>
          <Tab>
            <span>CRM Integrations</span>
            {crmIntegrations.length > 0 && (
              <BadgeCircle number={crmIntegrations.length} />
            )}
          </Tab>
          <Tab style={{ flex: 1}}>
            <span>Communication Integrations</span>
            {touchPointIntegrations.length > 0 && (
              <BadgeCircle number={touchPointIntegrations.length} />
            )}
          </Tab>
          </TabList>
          <TabPanel>
            <TeamSettingsTab />
          </TabPanel>
          <TabPanel>
            <CRMintegrationsTab updateIntegrations={setCustomerConfiguration} crmIntegrations={crmIntegrations} configurationId={customerConfiguration?.id} />
          </TabPanel>
          <TabPanel>
            <PhoneIntegrationsTab phoneIntegrations={touchPointIntegrations} />
          </TabPanel>
      </Tabs>
    </div>
  );
};

export default SettingsPage;
