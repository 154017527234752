import { TeamMemberWithTouchPoint } from "../../../../models/TeamMemberWithLatestTouchPoint";
import teamMemberResponseMapper, {
  TeamMemberResponse,
} from "./TeamMemberResponse";
import touchPointResponseMapper, {
  TouchPointResponse,
} from "./TouchPointResponse";

export type TeamMemberWithLatestTouchPointResponse = {
  customerId: string;
  teamMember: TeamMemberResponse;
  touchPoint: TouchPointResponse;
};

const teamMemberWithTouchPointResponseMapper = {
  toModel(
    response: TeamMemberWithLatestTouchPointResponse
  ): TeamMemberWithTouchPoint {
    return {
      customerId: response.customerId,
      teamMember: teamMemberResponseMapper.toModel(response.teamMember),
      touchPoint: touchPointResponseMapper.toModel(response.touchPoint),
    };
  },
};

export default teamMemberWithTouchPointResponseMapper;
