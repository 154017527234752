import axios from "axios";
import { logger } from "../../infrastructure/logger";
import { CommVisionToken } from "../../security/commVisionToken";
import { InitiateMergeIntegrationSetupRequest } from "./models/requests/InitiateMergeIntegrationSetupRequest";
import { IConnectorsApiClient } from "./IConnectorsApiClient";
import { CustomerConfigurationResponse } from "../sharedModels/responses/CustomerConfigurationResponse";
import { CompleteMergeIntegrationSetupRequest } from "./models/requests/CompleteMergeIntegrationSetupRequest";

export class ConnectorsApiClient implements IConnectorsApiClient {
  private BASE_URL = process.env.REACT_APP_CONNECTORS_API_URL + "/v1";
  private MERGE_SETUP__URL = `${this.BASE_URL}/setup/merge`;

  public async initiateMergeIntegrationSetup(
    authDetails: CommVisionToken | null,
    request: InitiateMergeIntegrationSetupRequest
  ): Promise<string> {
    if (!authDetails) {
      throw new Error("Authentication failed");
    }

    const { customerId, token } = authDetails;

    logger.log("initiating merge integration setup", {
      customerId,
      emailAddress: request.emailAddress,
      companyName: request.companyName,
    });

    const result = await axios.post(this.MERGE_SETUP__URL, request, {
      params: { customerId },
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    if (result.status === 200) {
      return result.data;

      // TODO: HANDLE ERRORS TO DISPLAY IN FRONT END
    } else {
      throw new Error(
        `Failed to initial merge integration setup: ${result.status} - ${result.statusText}`
      );
    }
  }

  public async completeMergeIntegrationSetup(
    authDetails: CommVisionToken | null,
    request: CompleteMergeIntegrationSetupRequest
  ): Promise<CustomerConfigurationResponse | undefined> {
    if (!authDetails) {
      throw new Error("Authentication failed");
    }

    const { customerId, token } = authDetails;

    logger.log("completing merge integration setup", {
      customerConfigurationId: request.customerConfigurationId,
      customerId,
    });

    const result = await axios.post(
      `${this.MERGE_SETUP__URL}/complete`,
      request,
      {
        params: { customerId },
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    if (result.status === 200) {
      return result.data as CustomerConfigurationResponse;

      // TODO: HANDLE ERRORS TO DISPLAY IN FRONT END
    } else {
      throw new Error(
        `Failed to complete merge integration setup: ${result.status} - ${result.statusText}`
      );
    }
  }

}
