import { useCallback, useEffect, useState } from "react";
import { useAuthDetails } from "../../hooks/useAuthDetails";
import { logger } from "../../infrastructure/logger";
import { PaginationProps } from "../../components/Pagination";
import BreadCrumb from "../../components/BreadCrumb";
import HomeLine from "@untitled-ui/icons-react/build/esm/HomeLine";
import { SortDirection } from "../../models/SortBy";
import ParticipantsList from "../../components/ParticipantsList";
import { meetingApiClientFactory } from "../../clients/meetings/MeetingApiClientFactory";
import { Participant } from "../../clients/meetings/models/Participant";
import { Search } from "../../models/Search";

const ParticipantsPage = () => {
  const defaultItemsPerPage = 25;
  const defaultSortingColumn = "Name";

  const authDetails = useAuthDetails();
  const [loading, setLoading] = useState(false);
  const [resultsAreFiltered, setResultsAreFiltered] = useState(false);
  const [searchText, setSearchText] = useState<string | undefined>(undefined);
  const [participants, setParticipants] = useState<Participant[]>([]);
  const [error, setError] = useState<string | undefined>(undefined);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [activeColumn, setActiveColumn] = useState([defaultSortingColumn]);
  const [sortingColumn, setSortingColumn] = useState([defaultSortingColumn]);
  const [sortingDirection, setSortingDirection] = useState<SortDirection>(
    SortDirection.Ascending
  );
  const [itemsPerPage, setItemsPerPage] = useState(defaultItemsPerPage);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const paginationSettings: PaginationProps = {
    currentPage,
    itemsPerPage,
    totalItems,
    onPageChange: handlePageChange,
  };

  const loadParticipants = useCallback(
    async (sortByColumn?: string, sortByDirection?: SortDirection) => {
      if (!authDetails) return;

      const sortBy =
        sortByColumn && sortByDirection
          ? [{ propertyName: sortByColumn, direction: sortByDirection }]
          : undefined;

      const ignoreInternalParticipants = true;

      const data = await meetingApiClientFactory.client
        .v1()
        .getParticipants(
          authDetails,
          ignoreInternalParticipants,
          currentPage,
          itemsPerPage,
          sortBy
        );

      if (!data?.results) {
        return;
      }

      if (data.totalCount > totalItems) {
        setItemsPerPage(itemsPerPage);
      }

      setParticipants(data.results || []);
      setTotalItems(data.totalCount || 0);
      setResultsAreFiltered(false);
      setSearchText(undefined);
    },
    [authDetails, currentPage, totalItems, itemsPerPage]
  );

  const loadFilteredParticipants = useCallback(
    async (
      searchFor: string,
      sortByColumn?: string,
      sortByDirection?: SortDirection
    ) => {
      if (!authDetails) return false;

      const sortBy =
        sortByColumn && sortByDirection
          ? [{ propertyName: sortByColumn, direction: sortByDirection }]
          : undefined;

      const search: Search = {
        phrase: searchFor,
        searchOn: ["Name", "Email", "Company.Name"],
      };

      const ignoreInternalParticipants = true;

      const data = await meetingApiClientFactory.client
        .v1()
        .searchParticipants(
          authDetails,
          search,
          ignoreInternalParticipants,
          currentPage,
          itemsPerPage,
          sortBy
        );

      if (!data?.results) {
        return;
      }

      if (data.totalCount > totalItems) {
        setItemsPerPage(itemsPerPage);
      }

      setParticipants(data.results || []);
      setTotalItems(data.totalCount || 0);
      setResultsAreFiltered(true);
      setSearchText(searchFor);

      if (!sortBy) {
        setActiveColumn([]);
        setSortingColumn([]);
      }
    },
    [authDetails, currentPage, totalItems, itemsPerPage]
  );

  const onSearchChange = useCallback(
    async (searchFor: string | undefined) => {
      if (!authDetails) return;

      if (searchFor) {
        await loadFilteredParticipants(searchFor);
      } else {
        await loadParticipants();
        setSearchText(undefined);
        setActiveColumn([]);
        setSortingColumn([]);
      }
    },
    [authDetails, loadFilteredParticipants, loadParticipants]
  );

  useEffect(() => {
    setLoading(true);
    loadParticipants()
      .catch((e) => {
        if (e instanceof Error) {
          setError(e.message);
          logger.error("loadParticipants", { error: e });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [loadParticipants]);

  const breadCrumbs = [
    { name: "Home", link: "/", icon: HomeLine },
    { name: "Participants", link: "/participants" },
  ];

  const sortByColumnOnClick = (column: string) => {
    if (sortingColumn?.includes(column)) {
      if (resultsAreFiltered) {
        loadFilteredParticipants(searchText!, column, SortDirection.Descending);
      } else {
        loadParticipants(column, SortDirection.Descending);
      }

      setSortingColumn([]);
      setSortingDirection(SortDirection.Descending);
    } else {
      if (resultsAreFiltered) {
        loadFilteredParticipants(searchText!, column, SortDirection.Ascending);
      } else {
        loadParticipants(column, SortDirection.Ascending);
      }

      setSortingColumn([`${column}`]);
      setSortingDirection(SortDirection.Ascending);
    }

    setActiveColumn([`${column}`]);
  };

  return (
    <div>
      <BreadCrumb items={breadCrumbs} />
      <h1 className="text-4xl capitalize font-bold mb-8">
        Participants From Interactions
      </h1>
      <div className="mt-5">
        {error && (
          <div className="row">
            <div className="card large error">
              <section>
                <p>
                  <span className="icon-alert inverse "></span>
                  {error}
                </p>
              </section>
            </div>
          </div>
        )}

        <ParticipantsList
          participants={participants}
          pagination={paginationSettings}
          sortDirection={sortingDirection}
          activeColumns={activeColumn}
          sortingColumns={sortingColumn}
          sortByColumnOnClick={sortByColumnOnClick}
          onSearchChange={onSearchChange}
        />

        {loading && (
          <div className="center-page">
            <span className="spinner primary"></span>
            <p>Loading...</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default ParticipantsPage;
