import React, { useState, useEffect } from "react";
import { useDebounce } from "use-debounce";

type DebouncedInputProps = {
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
  delay?: number; // Delay in milliseconds
  className: string;
};

export const DebouncedInput: React.FC<DebouncedInputProps> = ({
  value,
  onChange,
  className,
  placeholder = "",
  delay = 250,
}) => {
  const [inputValue, setInputValue] = useState(value);

  // Create a debounced value
  const [debouncedValue] = useDebounce(inputValue, delay);

  /**
   * Call the onChange function when a user stops typing
   */
  useEffect(() => {
    onChange(debouncedValue);
  }, [debouncedValue, onChange]);

  return (
    <input
      type="text"
      value={inputValue}
      onChange={(e) => setInputValue(e.target.value)}
      placeholder={placeholder}
      className={`${className} focus:outline-none`}
    />
  );
};
