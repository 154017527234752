import { ITouchPointApiClient } from "../clients/touchpoints/ITouchPointApiClient";
import touchPointResponseMapper from "../clients/touchpoints/models/responses/TouchPointResponse";
import { Results } from "../models/Results";
import { SortBy } from "../models/SortBy";
import { TouchPoint } from "../models/TouchPoint";
import { CommVisionToken } from "../security/commVisionToken";
import { ITouchPointService } from "./ITouchPointService";

export class TouchPointService implements ITouchPointService {
  constructor(private readonly touchPointApiClient: ITouchPointApiClient) {}

  public async getTouchPoints(
    authDetails: CommVisionToken | null,
    page: number,
    pageSize: number,
    sortBy?: SortBy[]
  ): Promise<Results<TouchPoint>> {
    const response = await this.touchPointApiClient.getTouchPoints(
      authDetails,
      page,
      pageSize,
      sortBy
    );

    if (!response) {
      return { items: [], totalCount: 0, hasMore: false, resultsCount: 0 };
    }

    const data = response.results.map((x) =>
      touchPointResponseMapper.toModel(x)
    );

    return {
      items: data,
      totalCount: response.totalCount,
      resultsCount: response.resultsCount,
      hasMore: response.hasMore,
    };
  }

  public async getTouchPointsByParticipantId(
    authDetails: CommVisionToken | null,
    participantId: string,
    page: number,
    pageSize: number,
    sortBy?: SortBy[]
  ): Promise<Results<TouchPoint>> {
    const response =
      await this.touchPointApiClient.getTouchPointsByParticipantId(
        authDetails,
        participantId,
        page,
        pageSize,
        sortBy
      );

    if (!response) {
      return { items: [], totalCount: 0, hasMore: false, resultsCount: 0 };
    }

    const data = response.results.map((x) =>
      touchPointResponseMapper.toModel(x)
    );

    return {
      items: data,
      totalCount: response.totalCount,
      resultsCount: response.resultsCount,
      hasMore: response.hasMore,
    };
  }

  public async getTouchPoint(
    authDetails: CommVisionToken | null,
    id: string
  ): Promise<TouchPoint | undefined> {
    const response = await this.touchPointApiClient.getTouchPoint(
      authDetails,
      id
    );

    if (!response) {
      return undefined;
    }

    return touchPointResponseMapper.toModel(response);
  }

  public async searchTouchPoints(
    authDetails: CommVisionToken | null,
    subjectSearchPhrase: string,
    page: number,
    pageSize: number,
    sortBy?: SortBy[]
  ): Promise<Results<TouchPoint>> {
    const response = await this.touchPointApiClient.searchTouchPoints(
      authDetails,
      subjectSearchPhrase,
      page,
      pageSize,
      sortBy
    );

    if (!response) {
      return { items: [], totalCount: 0, hasMore: false, resultsCount: 0 };
    }

    const data = response.results.map((x) =>
      touchPointResponseMapper.toModel(x)
    );

    return {
      items: data,
      totalCount: response.totalCount,
      resultsCount: response.resultsCount,
      hasMore: response.hasMore,
    };
  }
}
