import { useCallback, useEffect, useState } from 'react';
import { Integration } from '../../../models/Integration';
import { useAuthDetails } from '../../../hooks/useAuthDetails';
import { IConnectorsApiClient } from '../../../clients/connectors/IConnectorsApiClient';
import { ConnectorsApiClient } from '../../../clients/connectors/ConnectorsApiClient';
import { ConnectorsService } from '../../../services/ConnectorsService';
import { IConnectorsService } from '../../../services/IConnectorsService';
import { useMergeLink } from '@mergeapi/react-merge-link';
import CustomerConfiguration from '../../../models/CustomerConfiguration';
import { ConfigurationCard } from '../../../components/ConfigurationCard';

import { availableCRMIntegrationTypes, AvailableIntegrationType } from '../../../clients/sharedModels/responses/AvailableIntegrationTypes';
import { LoadingCard } from '../../../components/LoadingCard';

interface IntegrationProps {
    configurationId?: string;
    crmIntegrations?: Integration[];
    phoneIntegrations?: Integration[];
    updateIntegrations: (data: CustomerConfiguration) => void;
}


const connectorsApiClient: IConnectorsApiClient = new ConnectorsApiClient();
const connectorsService: IConnectorsService = new ConnectorsService(
  connectorsApiClient
);


export const CRMintegrationsTab = (integrationProps: IntegrationProps) => {

    const { configurationId, crmIntegrations,  updateIntegrations } = integrationProps;

    const authDetails = useAuthDetails();
    const [tempToken, setTempToken] = useState<string | boolean>(false);

    const [crmIntegrationTypes, setCrmIntegrationTypes] = useState<AvailableIntegrationType[]>([]);

    const [activeIntegration, setActiveIntegration] = useState<AvailableIntegrationType | null>(null);

    const [isLoaded, setIsLoaded] = useState(false);
    
    // loop through available and if matching with the connected crm, set the connected flag to true

    useEffect(() => {
        console.log(crmIntegrations, 'crmIntegrations')
        const crmIntegrationsWithConnectedFlag = availableCRMIntegrationTypes.map((integration) => {
            const connectedIntegration = crmIntegrations?.find((connectedIntegration) => connectedIntegration.integrationDetails?.name === integration.name);
            return {
                ...integration,
                connected: !!connectedIntegration,
                integrationDetails: connectedIntegration
            };
        });

        //review crmIntegrations for any missing integrations that aren't in the availableCRMIntegrationTypes
        const missingIntegrations = crmIntegrations?.filter((connectedIntegration) => {
            return !availableCRMIntegrationTypes.some((integration) => integration.name === connectedIntegration.integrationDetails?.name);
        });
        for(const missingIntegration of missingIntegrations || []) {
            crmIntegrationsWithConnectedFlag.push({
                name: missingIntegration.integrationDetails?.name || '',
                logoUrl: missingIntegration.integrationDetails?.logoUrl || '',
                description: '',
                id: missingIntegration.id,
                mergeName: missingIntegration.integrationDetails?.name || '',
                connected: true,
                integrationDetails: missingIntegration,
                commingSoon: true
            });
        }

        setCrmIntegrationTypes(crmIntegrationsWithConnectedFlag);
        setIsLoaded(true);
    }, [crmIntegrations]);





  const onGenerateTempToken = useCallback(
    async (emailAddress: string, companyName: string, integration: string|any = null) => {
      if (!configurationId) return;

      const token = await connectorsService.initiateMergeIntegrationSetup(
        authDetails,
        configurationId,
        emailAddress,
        companyName,
        integration
      );

      console.log(token, 'token')

      if (token) {
        setTempToken(token);
      }
    },
    [authDetails, configurationId]
  );


  const onSuccess = useCallback(
    async (public_token: string) => {
      if (!authDetails || !configurationId) return;

      const response = await connectorsService.completeMergeIntegrationSetup(
        authDetails,
        configurationId,
        public_token
      );

      if (response) {
        updateIntegrations(response);
      }
    },
    [authDetails, configurationId, updateIntegrations]
  );
  let { open, isReady } = useMergeLink({
        linkToken: tempToken.toString(),
        onSuccess,
        onExit: () => {
            setTempToken(false);
            setActiveIntegration(null);
            
            isReady = false;
        },
        tenantConfig: {
        apiBaseURL: process.env.REACT_APP_MERGE_BASE_URL,
        },
    })

    const openAllCRMs = async () => {
        await onGenerateTempToken(authDetails!.email, authDetails!.customerName);
    }

    const openCRMIntegration = async (integration: AvailableIntegrationType) => {
        setActiveIntegration(integration);
        await onGenerateTempToken(authDetails!.email, authDetails!.customerName, integration.mergeName);
    }


  useEffect(() => {
    // no token needed until click
    if (!isReady) return;
    if(!tempToken) return;
    console.log('opening')
    open();
    }, [tempToken, isReady, open]);

    return (
        <div>
            <div className='flex justify-between mb-6 mt-4'>

        <div className="justify-start w-3/4">
                <h2 className='text-2xl font-bold mb-2 mt-4'>Integrations and connected CRMs</h2>
                <p>Supercharge your workflows and connect the tools you use everyday.</p>
                
        </div>
        <div className="flex items-center justify-end w-1/4">

            <button className='border border-blue-500 text-blue-500 rounded-md px-4 py-2 hover:bg-blue-500 hover:text-white' 
            onClick={() => openAllCRMs()}>Connect new CRM Integration</button>
              </div>
              </div>
            <div>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                  {!isLoaded && <LoadingCard />}
                {isLoaded && crmIntegrationTypes.sort((a, b) => {
                  if(a.connected && !a.commingSoon && !b.connected) {
                      return -1;
                  }
                  if(b.connected && !b.commingSoon && !a.connected) {
                      return 1;
                  }
                  if(a.commingSoon && !b.commingSoon) {
                      return 1;
                  }
                  if(b.commingSoon && !a.commingSoon) {
                      return -1;
                  }
                  return 0;
              })
                .map((integration) => {
                    return (
                        <ConfigurationCard
                            key={integration.name}
                            integrationType={integration}
                            isLoading={activeIntegration?.id === integration.id}
                            openCRM={openCRMIntegration}
                        />
                    );
                })}
                </div>
            </div>
        </div>
    );
}