import React, { useEffect, useMemo, useRef, useState } from "react";
import SearchLg from "@untitled-ui/icons-react/build/esm/SearchLg";

interface TranscriptAgentProps {
  transcript: any;
  currentTime: number;
  moveAudio: (time: number) => void;
}

const TranscriptAgent = ({ transcript, currentTime, moveAudio }: TranscriptAgentProps) => {
  const [searchTerm, setSearchTerm] = useState("");
  const transcriptRef: any = useRef(null);

  // Function to handle scroll to the matched word
  const scrollToMatchedLine = (index: number) => {
    const target = transcriptRef.current?.querySelector(
      `[data-index='${index}']`
    );
    if (target) {
      target.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  // Highlighting matched word in the transcript
  const highlightText = (text: string, searchTerm: string) => {
    if (!searchTerm) return text;
    const regex = new RegExp(`(${searchTerm})`, "gi");
    return text.split(regex).map((part, index) =>
      regex.test(part) ? (
        <span key={index} className="bg-yellow-500">
          {part}
        </span>
      ) : (
        part
      )
    );
  };

  const moveAudioToTime = (time: number) => {
    //console.log("Move audio to time: ", time);
    moveAudio(time);
  }

  const bufferTime = 0.2; // Buffer time in seconds

  // Function to calculate time in seconds from "hh:mm:ss" format
  const timeToSeconds = (timeStr: string) => {
    const [hours, minutes, seconds] = timeStr.split(":").map(parseFloat);
    return hours * 3600 + minutes * 60 + seconds;
  };

  // Scroll to the active transcript line
  useEffect(() => {
    const activeElement = transcriptRef.current.querySelector(".active");
    if (activeElement) {
      activeElement.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [currentTime]);

  // Merging transcript lines based on speaker
  const mergedTranscript = useMemo(() => {
    if (!transcript || transcript.length === 0) return [];

    return transcript.reduce((acc: any[], curr: any) => {
      const lastItem = acc[acc.length - 1];
      const currStartTime = timeToSeconds(curr.relativeOffset);
      const currDuration = timeToSeconds(curr.duration);

      if (lastItem && lastItem.participantId === curr.participantId) {
        // Merge text for the same speaker regardless of small time gaps
        lastItem.displayText += ` ${curr.displayText}`;
        lastItem.endTime = currStartTime + currDuration; // Extend end time for the merged sentence
      } else {
        // Add as a new entry when speaker changes
        acc.push({
          ...curr,
          startTime: currStartTime, // Use relativeOffset as startTime
          endTime: currStartTime + currDuration, // Calculate endTime as startTime + duration
        });
      }
      return acc;
    }, []);
  }, [transcript]);

  return (
    <>
      <div className="flex items-center border border-gray-300 rounded-md w-full max-w-lg mb-5">
        {/* Search Icon Button */}
        <button className="p-2 bg-gray-100 border-r border-gray-300 rounded-l-md">
          <SearchLg className="w-5 h-5 text-gray-500" />
        </button>

        {/* Search Input */}
        <input
          type="text"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="Search for a keyword..."
          className="w-full p-2 rounded-r-md focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
      </div>

      <div
        ref={transcriptRef}
        style={{ overflowY: "scroll", maxHeight: "400px" }}
      >
        {mergedTranscript.map((line: any, index: any) => {
          const lineStartTime = line.startTime;
          const lineEndTime = line.endTime;
          const isActive = 
          (currentTime >= lineStartTime && currentTime <= lineEndTime) || 
          (currentTime < lineStartTime && currentTime + bufferTime >= lineStartTime);
    
          const displayText = highlightText(line.displayText, searchTerm);

          // If the search term matches, scroll to that line
          if (
            searchTerm &&
            line.displayText.toLowerCase().includes(searchTerm.toLowerCase())
          ) {
            scrollToMatchedLine(index);
          }

          return (
            <p
              key={index}
              data-index={index}
              onClick={() => moveAudioToTime(line.startTime)}
              className={
                (isActive ? "bg-commVision-700 text-white active rounded" : "") + " p-2 mb-1"
              }
            >
              <strong>{line.participantName}: </strong>
              {displayText}
            </p>
          );
        })}
      </div>
    </>
  );
};

export default TranscriptAgent;
