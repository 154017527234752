import axios from "axios";
import { logger } from "../../infrastructure/logger";
import { TouchPointResponse } from "./models/responses/TouchPointResponse";
import { TeamMemberWithLatestTouchPointResponse } from "./models/responses/TeamMemberWithTouchPointResponse";
import {
  notFoundPaginationResponse,
  PaginationResponse,
} from "../meetings/models/responses/PaginationResponse";
import { SortBy } from "../../models/SortBy";
import { SortByRequest } from "../sharedModels/requests/SortByRequest";
import { ITouchPointApiClient } from "./ITouchPointApiClient";
import { CommVisionToken } from "../../security/commVisionToken";

export class TouchPointApiClient implements ITouchPointApiClient {
  private BASE_URL = process.env.REACT_APP_MEETINGS_API_URL + "/v1";

  public async getTeamMembersWithTouchPoints(
    authDetails: CommVisionToken | null,
    page: number,
    pageSize: number
  ): Promise<PaginationResponse<TeamMemberWithLatestTouchPointResponse>> {
    if (!authDetails) {
      throw new Error("Authentication failed");
    }

    const { customerId, token } = authDetails;

    logger.log("get team members with latest touchpoint", { customerId });

    const result = await axios.get(
      `${this.BASE_URL}/teammembers/touchpoints/latest`,
      {
        params: { customerId, page, pageSize },
        headers: { Authorization: `Bearer ${token}` },
        withCredentials: true,
      }
    );

    if (result.status === 200) {
      return result.data;
    } else if (result.status === 404) {
      return notFoundPaginationResponse;
    } else {
      throw new Error(
        `Failed to get team members with latest touchpoint: ${result.status} - ${result.statusText}`
      );
    }
  }

  public async getTouchPoints(
    authDetails: CommVisionToken | null,
    page: number,
    pageSize: number,
    sortBy?: SortBy[]
  ): Promise<PaginationResponse<TouchPointResponse>> {
    if (!authDetails) {
      throw new Error("Authentication failed");
    }

    const { customerId, token } = authDetails;

    logger.log("get touch points", { customerId, page, pageSize, sortBy });

    let sort;

    if (sortBy) {
      sort = SortByRequest.ConvertFrom(sortBy);
    }

    const result = await axios.get(`${this.BASE_URL}/touchpoints`, {
      params: { customerId, page, pageSize, sortBy: sort },
      headers: { Authorization: `Bearer ${token}` },
      withCredentials: true,
    });

    if (result.status === 200) {
      return result.data;
    } else if (result.status === 404) {
      return notFoundPaginationResponse;
    } else {
      throw new Error(
        `Failed to get touch points: ${result.status} - ${result.statusText}`
      );
    }
  }

  public async getTouchPointsByParticipantId(
    authDetails: CommVisionToken | null,
    participantId: string,
    page: number,
    pageSize: number,
    sortBy?: SortBy[]
  ): Promise<PaginationResponse<TouchPointResponse>> {
    if (!authDetails) {
      throw new Error("Authentication failed");
    }

    const { customerId, token } = authDetails;

    logger.log("get touch points for participant", {
      customerId,
      participantId,
      page,
      pageSize,
      sortBy,
    });

    let sort;

    if (sortBy) {
      sort = SortByRequest.ConvertFrom(sortBy);
    }

    const result = await axios.get(
      `${this.BASE_URL}/participants/${participantId}/touchpoints`,
      {
        params: { customerId, page, pageSize, sortBy: sort },
        headers: { Authorization: `Bearer ${token}` },
        withCredentials: true,
      }
    );

    if (result.status === 200) {
      return result.data;
    } else if (result.status === 404) {
      return notFoundPaginationResponse;
    } else {
      throw new Error(
        `Failed to get touch points for participant: ${result.status} - ${result.statusText}`
      );
    }
  }

  public async getTouchPoint(
    authDetails: CommVisionToken | null,
    id: string
  ): Promise<TouchPointResponse | undefined> {
    if (!authDetails) {
      throw new Error("Authentication failed");
    }

    const { customerId, token } = authDetails;

    logger.log("get touch point by id", { customerId, id });

    const result = await axios.get(`${this.BASE_URL}/touchpoints/${id}`, {
      params: { customerId },
      headers: { Authorization: `Bearer ${token}` },
      withCredentials: true,
    });

    if (result.status === 200) {
      return result.data;
    } else if (result.status === 404) {
      return undefined;
    } else {
      throw new Error(
        `Failed to get touch point by id: ${result.status} - ${result.statusText}`
      );
    }
  }

  public async searchTouchPoints(
    authDetails: CommVisionToken | null,
    subjectSearchPhrase: string,
    page: number,
    pageSize: number,
    sortBy?: SortBy[]
  ): Promise<PaginationResponse<TouchPointResponse>> {
    if (!authDetails) {
      throw new Error("Authentication failed");
    }

    const { customerId, token } = authDetails;

    logger.log("search touch points by subject", {
      customerId,
      page,
      pageSize,
      sortBy,
      subjectSearchPhrase,
    });

    let sort;

    if (sortBy) {
      sort = SortByRequest.ConvertFrom(sortBy);
    }

    const result = await axios.get(`${this.BASE_URL}/touchpoints`, {
      params: {
        customerId,
        page,
        pageSize,
        sortBy: sort,
        subjectPhrase: subjectSearchPhrase,
      },
      headers: { Authorization: `Bearer ${token}` },
      withCredentials: true,
    });

    if (result.status === 200) {
      return result.data;
    } else if (result.status === 404) {
      return notFoundPaginationResponse;
    } else {
      throw new Error(
        `Failed to search touch points by subject: ${result.status} - ${result.statusText}`
      );
    }
  }
}
