import { AvailableIntegrationType } from "../clients/sharedModels/responses/AvailableIntegrationTypes";
import { ToggleSwitch } from "./ToggleSwitch";
import { ComingSoonBadge } from "./ComingSoonBadge";
import ClipLoader from "react-spinners/ClipLoader";

// rounded card, header with logo and image left and button right, card body and then a seperate card footer with a link
export interface ConfigurationCardProps {
  key: string;
  integrationType: AvailableIntegrationType;
  openCRM?: (integration: AvailableIntegrationType) => any;
  isLoading?: boolean;
}

export const ConfigurationCard = (props: ConfigurationCardProps) => {
  const { integrationType, openCRM, isLoading } = props;
  const connectCRM = () => {
    console.log("connect crm");
    if (openCRM) {
      openCRM(integrationType);
      return;
    }
  };
  const disconnectIntegration = () => {
    console.log("disconnect crm");
    return () => {
      console.log("disconnect crm");
    };
  };
  return (
    <div
      className={
        "rounded-lg border-gray-200 border shadow-lg bg-white relative"
      }
    >
      {isLoading && (
        <div className="absolute inset-0 bg-white bg-opacity-75 flex items-center justify-center">
          <ClipLoader color={"#0721B3"} loading={true} size={50} />
        </div>
      )}
      <div className="flex items-center justify-between p-4">
        <div className="flex items-center">
          <img
            className="w-12 h-12 rounded-full"
            src={integrationType?.logoUrl} // random image
            alt="avatar"
          />
          <div className="ml-2">
            <h3 className="text-lg font-semibold">{integrationType?.name}</h3>
          </div>
        </div>
        {integrationType.connected && integrationType.commingSoon !== true && (
          <>
            <ToggleSwitch
              toggleStatus={integrationType.connected}
              onToggle={disconnectIntegration()}
            />
          </>
        )}
        {!integrationType.connected &&
          integrationType.commingSoon !== true &&
          openCRM && (
            <>
              <ToggleSwitch toggleStatus={false} onToggle={connectCRM} />
            </>
          )}
        {integrationType.commingSoon === true && (
          <>
            <ComingSoonBadge />
          </>
        )}
      </div>
      <div className="p-4 min-h-24">
        {integrationType.description && (
          <p className="text-sm text-gray-700">
            {integrationType?.description}
          </p>
        )}
      </div>
      <div className=" text-right p-4 border-t border-gray-200 bg-white">
        <a href="/#" className="text-md text-bold text-blue-600 hover:underline">
          View Integration
        </a>
      </div>
    </div>
  );
};
