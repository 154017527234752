export interface PaginationResponseBase<TResponse> {
  results: TResponse[];
  totalCount: number;
  resultsCount: number;
  hasMore: boolean;
}

export type PaginationResponse<TResponse> = {
  results: TResponse[];
  totalCount: number;
  resultsCount: number;
  hasMore: boolean;
};

export const notFoundPaginationResponse = {
  results: [],
  totalCount: 0,
  resultsCount: 0,
  hasMore: false,
};
