import { TeamMember } from "../../../../models/TeamMember";

export type TeamMemberResponse = {
  id: string;
  customerId: string;
  name: string;
  email: string;
};

const teamMemberResponseMapper = {
  toModel(response: TeamMemberResponse): TeamMember {
    return {
      id: response.id,
      customerId: response.customerId,
      name: response.name,
      email: response.email,
    };
  },
};

export default teamMemberResponseMapper;
