import React from "react";

export interface PaginationProps {
  currentPage: number;
  itemsPerPage: number;
  totalItems: number;
  onPageChange: (page: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  itemsPerPage,
  totalItems,
  onPageChange,
}) => {
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  return (
    <div className="flex mb-4 mt-10 xs:mt-0">
      <div className="w-1/5">
        <button
          className="flex items-center justify-center px-3 h-8 text-sm font-medium text-black bg-[#D3D3D3] hover:bg-[#B3B3B3]"
          key={currentPage}
          onClick={() => onPageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          <svg
            className="w-3.5 h-3.5 me-2 rtl:rotate-180"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 10"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M13 5H1m0 0 4 4M1 5l4-4"
            />
          </svg>
          Prev
        </button>
      </div>
      <div className="w-3/5 items-center content-center text-center">
        <span className="text-sm text-gray-400">
          Showing{" "}
          <span className="font-semibold">
            {itemsPerPage * currentPage - itemsPerPage + 1}
          </span>{" "}
          to{" "}
          <span className="font-semibold">
            {itemsPerPage * currentPage > totalItems
              ? totalItems
              : itemsPerPage * currentPage}
          </span>{" "}
          of <span className="font-semibold">{totalItems}</span> Entries
        </span>
      </div>

      <div className="w-1/5 justify-end flex mr-[12px]">
        <button
          className="flex items-center justify-center px-3 h-8 text-sm font-medium text-black bg-[#D3D3D3] hover:bg-[#B3B3B3]"
          key={currentPage}
          onClick={() => onPageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          Next
          <svg
            className="w-3.5 h-3.5 ms-2 rtl:rotate-180"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 10"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M1 5h12m0 0L9 1m4 4L9 9"
            />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default Pagination;
