import { IMeetingClient } from "./IMeetingClient";
import meetingApiV1Client from "./MeetingApiV1Client"; // Default import

const meetingApiClientFactory = {
  client: {
    v1: (): IMeetingClient => {
      return meetingApiV1Client;
    },
  },
};

export { meetingApiClientFactory };
