// create a module named StatusContainer.tsx that can output tailwind styled components based on status text

import React, { useState } from "react";
import { Tooltip } from "react-tooltip";

interface StatusContainerInterface {
  status: string;
  correlationId?: string;
}

const useCopyToClipboard = () => {
  const [isCopied, setIsCopied] = useState(false);

  const copyToClipboard = async (content: string) => {
    try {
      await navigator.clipboard.writeText(content);
      setIsCopied(true);
    } catch (error) {
      setIsCopied(false);
    }
  };

  return { isCopied, copyToClipboard };
};

const StatusContainer: React.FC<StatusContainerInterface> = ({
  status,
  correlationId,
}) => {
  let statusColour = "gray";

  switch (status) {
    case "Complete":
      statusColour = "bg-[#d0ecd1] border-[#68bc6e] text-[#68bc6e]";
      break;
    case "Processing":
      statusColour = "bg-[#e6e1c4] border-[#be8831] text-[#be8831]";
      break;
    case "Error":
      statusColour = "bg-[#e6bbb7] border-[#de5f53] text-[#c23427]";
      break;
    case "Started":
      statusColour = "bg-[#c3d9ef] border-[#2e71b3] text-[#2e71b3]";
      break;
  }

  const { copyToClipboard } = useCopyToClipboard();

  return (
    <div>
      <Tooltip id={correlationId} />
      <div
        className={`${statusColour} text-center min-w-24 pl-3 pr-3 p-1 text-sm rounded-lg border`}
        data-tooltip-id={correlationId}
        data-tooltip-content={`CorrelationId: ${correlationId}`}
        onClick={() => copyToClipboard(correlationId ?? "unknown")}
      >
        {status !== "Unknown" ? status : null}
      </div>
    </div>
  );
};

export default StatusContainer;
