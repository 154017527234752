import React from 'react';
import { AuthProvider } from 'react-oidc-context';
import { authConfig } from '../security/authConfig';

interface AuthProviderWithHistoryProps {
  children: React.ReactNode;
}

const AuthProviderWithHistory: React.FC<AuthProviderWithHistoryProps> = ({ children }) => {
  return <AuthProvider {...authConfig} onSigninCallback={(user) => {
    console.log('User signed in', user);
    // remove the code and state parameters from the url when you are redirected from the authorize page
    window.history.replaceState({}, document.title, window.location.pathname);
    // Return to original url that we tried to go to prior to login
    if(localStorage.getItem('redirectUri')) {
      window.location.href = localStorage.getItem('redirectUri') as string;
      localStorage.removeItem('redirectUri');
    }
  }}>{children}</AuthProvider>;
};

export default AuthProviderWithHistory;
