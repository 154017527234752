import React, { useState } from "react";
import { Link } from "react-router-dom";
import Pagination, { PaginationProps } from "../../components/Pagination";
import { ThinParticipant } from "../../models/ThinParticipant";
import moment from "moment";

interface ParticipantListProps {
  meetingParticipants: ThinParticipant[];
  pagination?: PaginationProps;
}

function MeetingParticipantsList({
  meetingParticipants: participants,
  pagination,
}: ParticipantListProps) {
  const [expandedParticipantId, setExpandedParticipantId] = useState<
    string | null
  >(null);

  const toggleSubRow = (participantId: string) => {
    setExpandedParticipantId(
      expandedParticipantId === participantId ? null : participantId
    );
  };

  // make table full width and pretty in tailwind
  return (
    <React.Fragment>
      <table className="table-auto w-full text-left min-w-full divide-y divide-gray-200  border border-gray-200 rounded-lg p-3">
        <thead>
          <tr>
            <th className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase ">
              Name
            </th>
            <th className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase ">
              Email
            </th>
            <th className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase ">
              Company
            </th>
            <th className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase ">
              CommVision User
            </th>
            <th className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase ">
              Attendance
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 ">
          {participants.map((participant) => (
            <React.Fragment key={participant.id}>
              <tr className="mb-3 mt-3">
                <td className="px-6 py-4 whitespace-nowrap text-md font-medium text-gray-800 ">
                  <Link to={"/participants/" + participant.id}>
                    {participant.name}
                  </Link>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-md font-medium text-gray-800 ">
                  {participant.email}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-md font-medium text-gray-800 ">
                  {participant.company?.name}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-md font-medium text-gray-800 ">
                  {participant.isInternal ? "Yes" : "No"}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-md font-medium text-gray-800 ">
                  { (participant.attendances === null || participant.attendances?.length === 0) && (
                    <span className="text-white bg-red-400 p-3 text-xs w-full rounded">No show</span>
                  )}
                  { participant.attendances != null && participant.attendances?.length > 0 && (
                  <button
                  onClick={() => toggleSubRow(participant.id)}
                  className="btn bg-commVision-700 text-white p-1 rounded-sm w-8 mt-3"
                >
                  {expandedParticipantId === participant.id ? "-" : "+"}
                </button>
                  )}
                </td>
              </tr>
              {expandedParticipantId === participant.id &&
                participant.attendances != null && (
                  <tr className="sub-row pb-4">
                    <td
                      colSpan={8}
                      className="pb-3 px-6 py-4 whitespace-nowrap text-md font-medium text-gray-800 "
                    >
                      {participant.attendances.map((attendance) => (
                        <React.Fragment>
                          <table className="w-full bg-white max-w-xl rounded-lg border border-gray-200 mb-2 mt-2">
                            <tbody>
                              <tr className="border-b">
                                <td
                                  colSpan={3}
                                  className="px-4 py-2 font-bold text-gray-700 bg-gray-300"
                                >
                                  Joined
                                </td>
                                <td className="px-4 py-2 text-gray-600 text-right">
                                  {moment(attendance.timeJoined).format(
                                    "MMM Do [at] h:mm a"
                                  )}
                                </td>
                              </tr>
                              <tr className="border-b">
                                <td
                                  colSpan={3}
                                  className="px-4 py-2 font-bold text-gray-700 bg-gray-300"
                                >
                                  Left
                                </td>
                                <td className="px-4 py-2 text-gray-600 text-right">
                                  {moment(attendance.timeLeft).format(
                                    "MMM Do [at] h:mm a"
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td
                                  colSpan={3}
                                  className="px-4 py-2 font-bold text-gray-700 bg-gray-300"
                                >
                                  Total Duration
                                </td>
                                <td className="px-4 py-2 text-gray-600  text-right">
                                  {moment
                                    .duration(
                                      attendance.totalDurationInSeconds,
                                      "seconds"
                                    )
                                    .humanize()}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </React.Fragment>
                      ))}
                    </td>
                  </tr>
                )}
            </React.Fragment>
          ))}
        </tbody>
      </table>
      {pagination && (
        <Pagination
          currentPage={pagination.currentPage}
          itemsPerPage={pagination.itemsPerPage}
          totalItems={pagination.totalItems}
          onPageChange={pagination.onPageChange}
        />
      )}
    </React.Fragment>
  );
}

export default MeetingParticipantsList;
