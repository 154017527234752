import { ConfigurationData } from "../../../models/ConfigurationData";

export type ConfigurationDataResponse = {
  accountToken: string;
};

const configurationDataResponseMapper = {
  toModel(response: ConfigurationDataResponse): ConfigurationData {
    return {
      accountToken: response.accountToken,
    };
  },
};

export default configurationDataResponseMapper;
