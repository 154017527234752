import { ThinCompany } from "../../../../models/ThinCompany";

export type ThinCompanyResponse = {
  id: string;
  customerId: string;
  name: string;
  website: string;
};

const thinCompanyResponseMapper = {
  toModel(response: ThinCompanyResponse): ThinCompany {
    const result = {
      id: response.id,
      customerId: response.customerId,
      name: response.name,
      website: response.website,
    };

    return result;
  },
};

export default thinCompanyResponseMapper;
