import { ITouchPointApiClient } from "../clients/touchpoints/ITouchPointApiClient";
import teamMemberWithTouchPointResponseMapper from "../clients/touchpoints/models/responses/TeamMemberWithTouchPointResponse";
import { TeamMemberWithTouchPoint } from "../models/TeamMemberWithLatestTouchPoint";
import { CommVisionToken } from "../security/commVisionToken";
import { ITeamMembersService } from "./ITeamMembersService";

export class TeamMembersService implements ITeamMembersService {
  constructor(private readonly touchPointApiClient: ITouchPointApiClient) {}

  public async getTeamMembersWithTouchPoints(
    authDetails: CommVisionToken | null,
    page: number = 1,
    pageSize: number = 25
  ): Promise<TeamMemberWithTouchPoint[]> {
    const response =
      await this.touchPointApiClient.getTeamMembersWithTouchPoints(
        authDetails,
        page,
        pageSize
      );

    if (!response) {
      return [];
    }

    return response.results.map((x) =>
      teamMemberWithTouchPointResponseMapper.toModel(x)
    );
  }
}
