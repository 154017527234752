import { useCallback, useEffect, useState } from "react";
import { ITouchPointApiClient } from "../../clients/touchpoints/ITouchPointApiClient";
import { TouchPointApiClient } from "../../clients/touchpoints/TouchPointApiClient";
import { TeamMembersService } from "../../services/TeamMembersService";
import { useAuthDetails } from "../../hooks/useAuthDetails";
import { TeamMemberWithTouchPoint } from "../../models/TeamMemberWithLatestTouchPoint";
import TeamMembersList from "../../components/TeamMembersList";
import { logger } from "../../infrastructure/logger";
import { ITeamMembersService } from "../../services/ITeamMembersService";
import HomeLine from "@untitled-ui/icons-react/build/esm/HomeLine";
import BreadCrumb from "../../components/BreadCrumb";

const touchPointApiClient: ITouchPointApiClient = new TouchPointApiClient();
const teamMembersService: ITeamMembersService = new TeamMembersService(
  touchPointApiClient
);

const TeamMembersPage = () => {
  const authDetails = useAuthDetails();
  const [loading, setLoading] = useState(false);
  const [teamMembers, setTeamMembers] = useState<TeamMemberWithTouchPoint[]>(
    []
  );
  const [error, setError] = useState<string | undefined>(undefined);

  const loadTeamMembers = useCallback(async () => {
    if (!authDetails) return;

    const data = await teamMembersService.getTeamMembersWithTouchPoints(
      authDetails
    );

    setTeamMembers(data);
  }, [authDetails]);

  useEffect(() => {
    setLoading(true);
    loadTeamMembers()
      .catch((e) => {
        if (e instanceof Error) {
          setError(e.message);
          logger.error("loadTeamMembers", { error: e });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [loadTeamMembers]);

  const breadCrumbs = [
    { name: "Home", link: "/", icon: HomeLine },
    { name: "Team Members", link: "/team_members" },
  ];

  return (
    <div>
      <BreadCrumb items={breadCrumbs} />
      <h1 className="text-4xl capitalize font-bold mb-8">Your team members</h1>
      <div className="mt-5">
        {error && (
          <div className="row">
            <div className="card large error">
              <section>
                <p>
                  <span className="icon-alert inverse "></span>
                  {error}
                </p>
              </section>
            </div>
          </div>
        )}

        <TeamMembersList teamMembers={teamMembers} />

        {loading && (
          <div className="center-page">
            <span className="spinner primary"></span>
            <p>Loading...</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default TeamMembersPage;
