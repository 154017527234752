import { IConnectorsApiClient } from "../clients/connectors/IConnectorsApiClient";
import { CompleteMergeIntegrationSetupRequest } from "../clients/connectors/models/requests/CompleteMergeIntegrationSetupRequest";
import { InitiateMergeIntegrationSetupRequest } from "../clients/connectors/models/requests/InitiateMergeIntegrationSetupRequest";
import customerConfigurationResponseMapper from "../clients/sharedModels/responses/CustomerConfigurationResponse";
import { logger } from "../infrastructure/logger";
import { CustomerConfiguration } from "../models/CustomerConfiguration";
import { CommVisionToken } from "../security/commVisionToken";
import { IConnectorsService } from "./IConnectorsService";

export class ConnectorsService implements IConnectorsService {
  constructor(private readonly connectorsApiClient: IConnectorsApiClient) {}

  public async initiateMergeIntegrationSetup(
    authDetails: CommVisionToken | null,
    customerConfigurationId: string,
    emailAddress: string,
    companyName: string,
    integration?: string
  ): Promise<string | null> {
    if (!authDetails) {
      throw new Error("Missing auth details");
    }

    const request: InitiateMergeIntegrationSetupRequest = {
      customerConfigurationId,
      emailAddress,
      companyName,
      integration: integration || null,
    };

    try {
      return await this.connectorsApiClient.initiateMergeIntegrationSetup(
        authDetails,
        request
      );
    } catch (error) {
      logger.error((error as Error).message);
      return null;
    }
  }

  public async completeMergeIntegrationSetup(
    authDetails: CommVisionToken | null,
    customerConfigurationId: string,
    publicToken: string
  ): Promise<CustomerConfiguration | undefined> {
    if (!authDetails) {
      throw new Error("Missing auth details");
    }

    const request: CompleteMergeIntegrationSetupRequest = {
      customerConfigurationId,
      publicToken,
    };

    try {
      const response =
        await this.connectorsApiClient.completeMergeIntegrationSetup(
          authDetails,
          request
        );

      if (response) {
        return customerConfigurationResponseMapper.toModel(response);
      }

      return undefined;
    } catch (error) {
      logger.error(error);
      return undefined;
    }
  }

  public async consentMicrosoftIntegration(
    authDetails: CommVisionToken | null
  ): Promise<String | any> {
    if (!authDetails) {
      throw new Error("Missing auth details");
    }

    try {
      
      // remove hard code later
      const redirectURI = `https://dev-teams.commvision.ai/api/teams/consentRedirect`;
      const tenantId = 'common';
      const clientId = 'c8211ea6-5493-4e75-a18f-b845fd53f604';
      const state = await this.generateStateCode(authDetails.customerId) // customerID
      const scope = 'User.Read Mail.Read Calendars.Read Contacts.Read';

      let adminConsentURL = `https://login.microsoftonline.com/${tenantId}/adminconsent?client_id=${clientId}&state=${state}&redirect_uri=${redirectURI}&scope=${scope}`;
      
      return adminConsentURL;
    } catch (error) {
      logger.error(error);
      return false;
    }
  }

  private async generateStateCode(customerId: string): Promise<string> {
    const hash = 'c2f7b3b3-1b1b-4b1b-8b1b-1b1b1b1b1b1b'
    const combined = customerId + hash;
    const encoder = new TextEncoder();
    const data = encoder.encode(combined);
    
    const hashBuffer = await crypto.subtle.digest('SHA-256', data);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray.map(byte => byte.toString(16).padStart(2, '0')).join('');

    const stringToEncode = {
      CustomerID: customerId,
      SecureID: hashHex
    }

    // base64 encode 
    const base64 = btoa(JSON.stringify(stringToEncode));
    return base64;
  }
}