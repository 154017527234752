
export const ComingSoonBadge = () => {
  return (
    <div className="border border-slate-300 rounded-2xl">
        <div className="flex items-center p-1 pl-2 pr-2">
            <div className="w-2 h-2 bg-blue-500 rounded-full"></div>
            <div className="ml-2 text-sm">Coming Soon</div>
        </div>
    </div>
  )
};
