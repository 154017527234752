import { ConfigurationData } from "./ConfigurationData";
import { ConfigurationType } from "./ConfigurationType";

export type Integration = {
  id: string;
  configurationType: ConfigurationType; // CRM or Touchpoint
  configurationData: ConfigurationData;
  name: string; // CRM Name
  logoUrl?: string;
  integrationDetails?: IntegrationType;
  connected?: boolean;
};

// map integrationName to CRMTypes
export const CRMTypes = {
  SALESFORCE: "Salesforce",
  HUBSPOT: "Hub Spot CRM",
  ZOHO: "Zoho CRM",
  DYNAMICS: "Dynamics",
  PIPEDRIVE: "Pipedrive",
  FRESHSALES: "Freshsales",
  FRESHDESK: "Freshdesk",
};

export async function findIntegrationByName(
  integrations: Integration[],
  name: string
): Promise<Integration | undefined> {
  return integrations.find((integration) => integration.name === name);
}

export async function findIntegrationByType(
  integrations: Integration[],
  type: ConfigurationType
): Promise<Integration | undefined> {
  return integrations.find(
    (integration) => integration.configurationType === type
  );
}

export interface IntegrationType {
  name: string;
  logoUrl: string;
  description?: string;
}

export const IntegrationTypes: { [key: string]: IntegrationType } = {
  Salesforce: {
    name: "Salesforce",
    logoUrl: "/images/salesforce.png",
  },
  Hubspot: {
    name: "Hubspot",
    logoUrl: "/images/hubspot.png",
  },
  Zoho: {
    name: "Zoho CRM",
    logoUrl: "/images/zoho.png",
  },
  Dynamics: {
    name: "Dynamics",
    logoUrl: "/images/dynamics.png",
  },
  Pipedrive: {
    name: "Pipedrive",
    logoUrl: "/images/pipedrive.png",
  },
  Freshsales: {
    name: "Freshsales",
    logoUrl: "/images/freshsales.png",
  },
  HubSpot: {
    name: "HubSpot",
    logoUrl: "/images/hubspot.png",
    description: 'HubSpot is a CRM platform that offers a variety of tools to help businesses grow. It collects and organizes data on your customers, tracks interactions, and automates certain processes.'
  },
}

export function getIntegrationDetails(crmName: string): IntegrationType {
  switch(crmName) {
    case CRMTypes.SALESFORCE:
      crmName = "Salesforce";
      break;
    case CRMTypes.HUBSPOT:
      crmName = "HubSpot";
      break;
    case CRMTypes.ZOHO:
      crmName = "Zoho";
      break;
    case CRMTypes.DYNAMICS:
      crmName = "Dynamics";
      break;
    case CRMTypes.PIPEDRIVE:
      crmName = "Pipedrive";
      break;
    case CRMTypes.FRESHSALES:
      crmName = "Freshsales";
      break;
    case CRMTypes.FRESHDESK:
      crmName = "Freshdesk";
      break;
    default:
      crmName = "Salesforce";
  }

  return IntegrationTypes[crmName];
}
