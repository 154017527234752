// badge with circle shape and number inside

import React from 'react';
import styled from 'styled-components';

interface BadgeCircleProps {
  number: number;
}

const BadgeCircle = ({ number }: BadgeCircleProps) => {
  return <BadgeCircleWrapper>{number}</BadgeCircleWrapper>;
};

const BadgeCircleWrapper = styled.div`
    display: inline-block;
    margin-left: 10px;
    text-align: center;
    font-size: 11pt;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #d3f4ff;
    border: 1px solid #0721B3;
    font-weight: normal;
    color: #0721B3;
    `;
export default BadgeCircle;
