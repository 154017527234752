import Pagination, { PaginationProps } from "./Pagination";
import SearchLg from "@untitled-ui/icons-react/build/esm/SearchLg";
import SortableTableHeading from "./SortableTableHeading";
import { SortDirection } from "../models/SortBy";
import { DebouncedInput } from "./DebouncedInput";
import { Participant } from "../clients/meetings/models/Participant";
import Avatar from "react-avatar";

interface ParticipantsListProps {
  participants: Participant[];
  pagination: PaginationProps;
  sortDirection: SortDirection;
  activeColumns: string[];
  sortingColumns: string[];
  sortByColumnOnClick: (column: string, sortDirection: SortDirection) => void;
  onSearchChange: (searchPhrase: string) => void;
}

const ParticipantsList = ({
  participants,
  pagination,
  sortDirection,
  activeColumns,
  sortingColumns,
  sortByColumnOnClick,
  onSearchChange,
}: ParticipantsListProps) => {
  return (
    <>
      <div className="flex mb-6">
        <div className="flex items-center justify-start w-1/2">
          {/* <button className="flex items-center justify-center p-6 h-10 text-md border-slate-300 text-gray-700 border font-bold rounded-lg">
            All Time
            <span>
              <IoClose size={28} />
            </span>
          </button> */}
        </div>
        <div className="flex items-center justify-end w-1/2">
          <div className="flex flex-row items-center border border-gray-300 rounded-md">
            <div className="p-2">
              <SearchLg />
            </div>
            <DebouncedInput
              value={""}
              onChange={onSearchChange}
              placeholder="Type to search..."
              delay={500} // Debounce delay in milliseconds
              className="w-full"
            />
          </div>
        </div>
      </div>
      <div className="">
        {/* table has light grey border around each row with first column big padding */}
        <table className="table-auto w-full text-left min-w-full divide-y divide-gray-200  border border-gray-200 rounded-lg p-3">
          <thead>
            <tr>
              <th className="p-2 px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 ">
                <SortableTableHeading
                  headingText="Name"
                  headingToSort="Name"
                  sortColumnDirection={sortDirection}
                  activeColumns={activeColumns}
                  sortingColumns={sortingColumns}
                  sortByColumnOnClick={sortByColumnOnClick}
                />
              </th>
              <th className="p-2 px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 ">
                <SortableTableHeading
                  headingText="Email"
                  headingToSort="Email"
                  sortColumnDirection={sortDirection}
                  activeColumns={activeColumns}
                  sortingColumns={sortingColumns}
                  sortByColumnOnClick={sortByColumnOnClick}
                />
              </th>
              <th className="p-2 px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 ">
                <SortableTableHeading
                  headingText="Company"
                  headingToSort="Company"
                  sortColumnDirection={sortDirection}
                  activeColumns={activeColumns}
                  sortingColumns={sortingColumns}
                  sortByColumnOnClick={sortByColumnOnClick}
                />
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 ">
            {participants.map((participant) => {
              return (
                <tr key={participant.id}>
                  <td className="px-6 py-4 whitespace-nowrap text-md font-bold text-gray-800 ">
                    <a href={`/participants/${participant.id}`}>
                      <div className="flex items-center gap-2">
                        <Avatar
                          name={participant.name}
                          size="30"
                          round={true}
                          textMarginRatio={0.05}
                        />
                        <div className="font-medium text-grey-600">
                          <div>{participant.name}</div>
                        </div>
                      </div>
                    </a>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-md font-medium text-gray-800 ">
                    {participant.email}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-md font-medium text-gray-800 ">
                    {participant.company?.name}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        {pagination.totalItems > pagination.itemsPerPage && (
          <Pagination
            currentPage={pagination.currentPage}
            itemsPerPage={pagination.itemsPerPage}
            totalItems={pagination.totalItems}
            onPageChange={pagination.onPageChange}
          />
        )}
      </div>
    </>
  );
};

export default ParticipantsList;
