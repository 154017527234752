import { Routes, Route } from "react-router-dom";
import HomePage from "./pages/home/HomePage";
import ProtectedRoute from "./components/ProtectedRoute";
import { RoutePath, routes } from "./routes";
import Layout from "./layout/Layout";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        {routes.map((route) => (
          <Route
            index={RoutePath.HOME === route.path}
            path={route.path}
            element={<ProtectedRoute component={route.component} />}
            key={route.name}
          />
        ))}
      </Route>
      {/* Redirect non-mapped routes to home page */}
      <Route path="*" element={<HomePage />} />
    </Routes>

    //
    // <>
    //   <header className="sticky">
    //     <span className="logo">CommVision</span>
    //     <NavLink to="/" className="button rounded">
    //       <span className="icon-home"></span>
    //       Home
    //     </NavLink>
    //     <NavLink to="/participants" className="button rounded">
    //       Participants
    //     </NavLink>
    //     <NavLink to="/meetings" className="button rounded">
    //       Meetings
    //     </NavLink>
    //     <NavLink to="/companies" className="button rounded">
    //       Companies
    //     </NavLink>
    //     <NavLink to="/profile" className="button rounded">
    //       Profile
    //     </NavLink>
    //     <AuthButton />
    //   </header>
    //   <div className="container">
    //     <Routes>
    //       <Route path="/" element={<HomePage />} />
    //       <Route
    //         path="/meetings"
    //         element={<ProtectedRoute component={MeetingsPage} />}
    //       />
    //       <Route
    //         path="/meetings/:id"
    //         element={<ProtectedRoute component={MeetingPage} />}
    //       />
    //       <Route
    //         path="/participants"
    //         element={<ProtectedRoute component={ParticipantsPage} />}
    //       />
    //       <Route
    //         path="/participants/:id"
    //         element={<ProtectedRoute component={ParticipantPage} />}
    //       />
    //       <Route
    //         path="/companies"
    //         element={<ProtectedRoute component={CompaniesPage} />}
    //       />
    //       <Route
    //         path="/companies/:id"
    //         element={<ProtectedRoute component={CompanyPage} />}
    //       />
    //       <Route
    //         path="/profile"
    //         element={<ProtectedRoute component={Profile} />}
    //       />
    //     </Routes>
    //   </div>
    // </>
  );
}

export default App;
