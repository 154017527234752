import React, { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import MeetingDetailBar from "../../components/MeetingDetailBar";
import MeetingParticipantsList from "./MeetingParticipantsList";
import { meetingApiClientFactory } from "../../clients/meetings/MeetingApiClientFactory";
import { PaginationProps } from "../../components/Pagination";
import ItemsPerPageSelector from "../../components/ItemsPerPageSelector";
import { PAGINATION_CONSTANTS } from "../../configs/constants";
import { useAuthDetails } from "../../hooks/useAuthDetails";
import { Tabs, Tab, TabList, TabPanel } from "react-tabs";
import BreadCrumb from "../../components/BreadCrumb";
import HomeLine from "@untitled-ui/icons-react/build/esm/HomeLine";
import MeetingRecording from "./MeetingRecording";
import { ITranscriptionService } from "../../services/ITranscriptionService";
import { TranscriptionService } from "../../services/TranscriptionService";
import { TranscriptionApiClient } from "../../clients/transcriptions/TranscriptionApiClient";
import { Transcription } from "../../models/Transcription";
import SummaryContent from "../../components/SummaryContent";
import { TouchPointService } from "../../services/TouchPointService";
import { ITouchPointService } from "../../services/ITouchPointService";
import { TouchPointApiClient } from "../../clients/touchpoints/TouchPointApiClient";
import { TouchPoint } from "../../models/TouchPoint";
import { ThinParticipant } from "../../models/ThinParticipant";

const transcriptionService: ITranscriptionService = new TranscriptionService(
  new TranscriptionApiClient()
);

const touchPointService: ITouchPointService = new TouchPointService(
  new TouchPointApiClient()
);

const MeetingPage = () => {
  const authDetails = useAuthDetails();
  const [loading, setLoading] = useState(false);
  const [meeting, setMeeting] = useState<TouchPoint | null>(null);
  //const [error, setError] = useState<string | null>(null);
  const params = useParams();
  const [id, setId] = useState<string | null>(null);

  const [transcription, setTranscription] = useState<Transcription | undefined>(
    undefined
  );

  const [meetingParticipants, setMeetingParticipants] = useState<
    ThinParticipant[]
  >([]);

  const [currentPage, setCurrentPage] = useState(
    meetingApiClientFactory.client.v1().startingPageNumber
  );
  const [itemsPerPage, setItemsPerPage] = useState(
    PAGINATION_CONSTANTS.DEFAULT_ITEMS_PER_PAGE
  );
  const [totalItems] = useState(0);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const breadCrumbs = [
    { name: "Home", link: "/", icon: HomeLine },
    { name: "Interactions", link: "/interactions" },
    {
      name: meeting?.subject || "Meeting",
      link: `/meeting/${id}`,
      active: true,
    },
  ];

  const handleItemsPerPageChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setItemsPerPage(Number(event.target.value));
    setCurrentPage(meetingApiClientFactory.client.v1().startingPageNumber);
  };

  const paginationSettings: PaginationProps = {
    currentPage,
    itemsPerPage,
    totalItems,
    onPageChange: handlePageChange,
  };

  const [recordings, setRecordings] = useState<string[] | null>(null);

  const loadTranscription = useCallback(
    async (meetingId: string) => {
      if (!authDetails) return;

      try {
        const data = await transcriptionService.getTranscriptionByTouchPointId(
          authDetails,
          meetingId
        );
        setTranscription(data);
        setRecordings(data?.combinedMediaUrl ? [data.combinedMediaUrl] : null);
        console.log(data, "transcription");
      } catch (e) {
        if (e instanceof Error) {
          //setError(e.message);
        }
      }
    },
    [authDetails]
  );

  const loadMeeting = useCallback(async () => {
    if (!authDetails) return;
    if (!id) return;
    console.log("Loading Meeting", id);
    try {
      setLoading(true);
      const data = await touchPointService.getTouchPoint(authDetails, id);
      console.log(data, "meeting");
      setMeeting(data || null);
      setMeetingParticipants(data?.participants || []);
      console.log(data?.participants, "participants");
      await loadTranscription(id);
      setLoading(false);

      setTabIndex(0);

      return data;
    } catch (e) {
      if (e instanceof Error) {
        //setError(e.message);
      }
      setLoading(false);
    }
  }, [authDetails, id, loadTranscription]);

  useEffect(() => {
    if (!params?.id) return;
    setId(params?.id ?? "");
    setLoading(true);
    loadMeeting();
  }, [loadMeeting, loadTranscription, params]);

  const [tabIndex, setTabIndex] = useState(0);

  return (
    <div>
      <>
        {loading && (
          <div className="center-page">
            <span className="spinner primary"></span>
            <p>Loading...</p>
          </div>
        )}

        {meeting && (
          <>
            <BreadCrumb items={breadCrumbs} />
            <h1 className="text-4xl capitalize font-bold">{meeting.subject}</h1>
            <MeetingDetailBar
              meeting={meeting}
              meetingParticipants={meetingParticipants}
            />
          </>
        )}

        <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
          <TabList>
            {transcription && <Tab>Summary</Tab>}
            {recordings && recordings.length > 0 && (
              <Tab>Recording & Transcript</Tab>
            )}
            <Tab>Attendees</Tab>
          </TabList>
          {transcription && (
            <TabPanel>
              <div className="mt-5 pt-2">
                <SummaryContent
                  summaryText={
                    transcription?.transcription?.conversationSummary ?? ""
                  }
                />
              </div>
            </TabPanel>
          )}
          {recordings && recordings.length > 0 && (
            <TabPanel>
              <MeetingRecording
                meeting={meeting!}
                transcriptionData={transcription}
                recordingUrl={recordings[0]}
              />
            </TabPanel>
          )}
          <TabPanel>
            {totalItems > PAGINATION_CONSTANTS.DEFAULT_ITEMS_PER_PAGE && (
              <ItemsPerPageSelector
                itemsPerPage={itemsPerPage}
                onItemsPerPageChange={handleItemsPerPageChange}
                maxItemsPerPage={
                  meetingApiClientFactory.client.v1().maxPageSize
                }
              />
            )}
            {meetingParticipants && meetingParticipants.length > 0 && (
              <div className="pt-4">
                <MeetingParticipantsList
                  meetingParticipants={meetingParticipants}
                  pagination={
                    totalItems > PAGINATION_CONSTANTS.DEFAULT_ITEMS_PER_PAGE
                      ? paginationSettings
                      : undefined
                  }
                />
              </div>
            )}
          </TabPanel>
        </Tabs>
      </>
    </div>
  );
};

export default MeetingPage;
