import { useAuth } from "react-oidc-context";
import { useMemo } from "react";
import { CommVisionToken } from "../security/commVisionToken";
import { decodeToken } from "react-jwt";

type Token = {
  customerId: string;
  token: string | undefined;
  email: string;
  organization: Organization;
};

type Organization = {
  [key: string]: OrganizationDetails;
};

type OrganizationDetails = {
  customerId: string[];
  customerName: string[];
};

export const useAuthDetails = (): CommVisionToken | null => {
  const auth = useAuth();
  const token = auth.user?.access_token;

  return useMemo(() => {
    if (!auth.isAuthenticated || !token) {
      return null;
    }

    const decodedToken = decodeToken<Token>(token);

    if (decodedToken) {
      const commVisionToken: CommVisionToken = {
        token,
        email: decodedToken.email,
        customerId: decodedToken.customerId,
        customerName:
          decodedToken.organization[Object.keys(decodedToken.organization)[0]]
            .customerName[0],
      };

      return commVisionToken;
    }

    return null;
  }, [auth.isAuthenticated, token]);
};
