// breadcrumb component

import ChevronRight from "@untitled-ui/icons-react/build/esm/ChevronRight";
import React from "react";
import { Link } from "react-router-dom";

interface BreadCrumbProps {
  items: { name: string; link: string, icon?: any, active?: boolean }[];
}


const BreadCrumb: React.FC<BreadCrumbProps> = ({ items }) => {
  return (
    <div className="flex items-center gap-2 text-lg text-gray-700 mt-4 mb-8">
      {items.map((item, index) => (
        <React.Fragment key={item.name}>
          <Link to={item.link} className={item.active ? "text-commVision-800 text-bold capitalize" : " capitalize"}>
            {item.icon ? <item.icon /> : item.name}
          </Link>
          {index < items.length - 1 && <span><ChevronRight /></span>}
        </React.Fragment>
      ))}
    </div>
  );
};

export default BreadCrumb;